import React from 'react';
import { setMonth, setYear } from 'date-fns';

import { Dropdown } from 'components/Dropdown';
import { Icon } from 'components/Icon';
import { range } from 'lodash';
import { months } from '../constants/options';
import { useNavigation } from 'react-day-picker';

const YearMonthPicker: React.FC<{
  since?: Date;
  until?: Date;
}> = ({ since, until }) => {
  const { goToDate, goToMonth, currentMonth } = useNavigation();

  const month = currentMonth.getMonth();
  const year = currentMonth.getFullYear();

  since = since || new Date(year - 100 - 1, month);
  until = until || new Date(year + 3, month);

  const years = range(until.getFullYear(), since.getFullYear()).map((year) => ({
    label: String(year),
    value: year,
  }));

  return (
    <div className="flex items-center justify-center">
      <div className="mx-2 z-10">
        <Dropdown
          name="datePickerMonth"
          value={month}
          options={months}
          renderInput={({ selectedItem, toggleMenu }) => (
            <div
              onClick={toggleMenu}
              className="relative flex items-center cursor-pointer"
            >
              <span>{selectedItem?.label}</span>
              <Icon name="keyboard_arrow_down" size={4} className="ml-1" />
            </div>
          )}
          onChange={(month) => goToMonth(setMonth(currentMonth, month))}
          usePortal={false}
          dropdownPlacement="bottom"
        />
      </div>
      <div className="mx-2 z-10">
        <Dropdown
          name="datePickerYear"
          value={year}
          options={years}
          renderInput={({ selectedItem, toggleMenu }) => (
            <div
              onClick={toggleMenu}
              className="relative flex items-center cursor-pointer"
            >
              <span>{selectedItem?.label}</span>
              <Icon name="keyboard_arrow_down" size={4} className="ml-1" />
            </div>
          )}
          onChange={(year) => goToDate(setYear(currentMonth, year))}
          usePortal={false}
          dropdownPlacement="bottom"
        />
      </div>
    </div>
  );
};

export default YearMonthPicker;
