import { useState } from 'react';
import { http } from '../http';
import { PaginationProps } from '../../components/Pagination';
import useDeepCompareEffect from 'use-deep-compare-effect';
import Axios from 'axios';
import omitBy from 'lodash/omitBy';
import map from 'lodash/map';

type Pagination = PaginationProps & { morePages: boolean };

type HttpMethod = 'GET' | 'POST' | 'PUT';

export function useRequest<T, M = unknown>(
  url: string,
  defaultValue: T,
  {
    params = {},
    method = 'GET',
    data = {},
  }: {
    params?: Record<string, any>;
    method?: HttpMethod;
    data?: Record<string, any>;
  } = {},
  setLoaded?: (value: boolean) => void,
  setResponse?: (value: any) => void,
): [
  T,
  {
    meta: M | undefined;
    pagination: Pagination;
    isLoading: boolean;
    refresh: () => void;
  },
] {
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    morePages: false,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState(defaultValue);
  const [meta, setMeta] = useState<M | undefined>(undefined);
  const [refreshToken, setRefreshToken] = useState(false);

  // find and replace string in params
  const encodeStringQuery = (params: any) => {
    const newParams = { ...params };
    Object.keys(newParams).forEach((key) => {
      if (typeof newParams[key] === 'string') {
        newParams[key] = encodeURIComponent(newParams[key]);
      }
    });
    return newParams;
  };

  useDeepCompareEffect(() => {
    const source = Axios.CancelToken.source();
    const requestConfig = {
      url,
      method,
      cancelToken: source.token,
      params: omitBy(encodeStringQuery(params) || {}, (v) => v === undefined || v === null || v === ''),
      data: method === 'POST' || method === 'PUT' ? data : undefined,
    };

    const requestPromise = (method === 'GET') 
      ? http.get<T>(url, requestConfig.params, { cancelToken: requestConfig.cancelToken })
      : (method === 'POST')
        ? http.post<T>(url, requestConfig.data, requestConfig.params, { cancelToken: requestConfig.cancelToken })
        : http.put<T>(url, requestConfig.data, { cancelToken: requestConfig.cancelToken });

    requestPromise
      .then((response) => {
        if (response.meta) {
          setMeta(response.meta);

          setPagination({
            currentPage: response.meta.current_page,
            totalPages: response.meta.last_page,
            morePages: response.meta.current_page < response.meta.last_page,
            totalRecords: response.meta.total,
          });
        }
        setState(response.data);
        setIsLoading(false);
        if (setLoaded) {
          setLoaded(true);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        if (Axios.isCancel(e)) {
          return;
        }

        if (e?.response?.status === 401) {
          if (setResponse) {
            setResponse(e.response);
          }

          return e;
        }

        if (e?.response?.status === 404) {
          if (setResponse) {
            setResponse(e.response);
          }
          return e;
        }
        throw e;
      });

    return () => source.cancel();
  }, [url, params, method, data, refreshToken]);

  return [
    state,
    {
      meta,
      pagination,
      isLoading,
      refresh() {
        setRefreshToken(!refreshToken);
      },
    },
  ];
}
