import { ReactNode, createContext } from 'react';

export interface NavbarContextProps {
  renderItem: ReactNode; 
  setRenderItem: React.Dispatch<React.SetStateAction<ReactNode>>;
}

const NavbarContext = createContext<NavbarContextProps | undefined>(undefined);

export default NavbarContext;
