import { MouseEventHandler } from 'react';
import { translate } from 'utils/translations';
import { Button } from 'components/Button';

export const NewContact = ({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div className="pb-8">
      <div className="flex items-center cursor-pointer" onClick={onClick}>
        <Button color="white" size="small" radius="full" icon="add" />
        <span className="text-gray-600 ml-4">
          {translate('Add new contact')}
        </span>
      </div>
    </div>
  );
};
