import React from 'react';
import { CaptionProps, useNavigation } from 'react-day-picker';

import { Icon } from 'components/Icon';

import YearMonthPicker from './YearMonthPicker';

const PrevNextButtons: React.FC<CaptionProps> = () => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  return (
    <div className="flex items-center justify-between py-3">
      <button
        onClick={() => previousMonth && goToMonth(previousMonth)}
        disabled={!previousMonth}
        className="cursor-pointer focus:outline-none"
        aria-label="Previous Month"
      >
        <Icon name="keyboard_arrow_left" size={6} className="text-gray-600" />
      </button>
      <YearMonthPicker />
      <button
        onClick={() => nextMonth && goToMonth(nextMonth)}
        disabled={!nextMonth}
        className="cursor-pointer focus:outline-none"
        aria-label="Next Month"
      >
        <Icon name="keyboard_arrow_right" size={6} className="text-gray-600" />
      </button>
    </div>
  );
};

export default PrevNextButtons;
