import { useContext, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import moment from 'moment';

import Caption from './Caption';
import DayContent from './DayContent';

import { AppContext } from 'contexts';

import 'react-day-picker/dist/style.css';
import '../DatePicker.css';

function DatePicker({
  value,
  onChange,
  isOpen,
  since,
  until,
}: {
  value: Date | null;
  onChange: (value: Date | null) => void;
  isOpen?: boolean;
  since?: string;
  until?: string;
}) {
  const [selected, setSelected] = useState<Date>(value || new Date());

  const { config } = useContext(AppContext);
  const { first_day: firstDayOfWeek } = config;

  return (
    <DayPicker
      disabled={
        since
          ? [
              {
                after: new Date(1970, 0, 1),
                before: moment(since).toDate(),
              },
            ]
          : []
      }
      fromDate={new Date(since)}
      toDate={new Date(until)}
      defaultMonth={selected}
      initialFocus={isOpen}
      selected={selected}
      weekStartsOn={firstDayOfWeek}
      onSelect={setSelected}
      onDayClick={onChange}
      showOutsideDays
      fixedWeeks
      classNames={{
        root: 'bg-white shadow-lg rounded-lg p-6',
        months: 'select-none focus:outline-none',
        head_row: 'flex -mx-2',
        row: 'flex -mx-2',
        cell: 'day-focus mx-1 rounded',
        day_today: 'today',
        day_selected: 'selected',
        day_disabled: 'disabled',
        day_outside: 'outside',
      }}
      components={{
        Caption,
        DayContent,
      }}
      formatters={{
        formatWeekdayName: (date) => (
          <abbr
            title={format(date, 'EEEE')}
            className="w-12 h-12 mx-2 flex items-center justify-center text-gray-600"
            style={{ textDecoration: 'none' }}
          >
            {format(date, 'EEEEEE')}
          </abbr>
        ),
      }}
    />
  );
}

export default DatePicker;
