import React from 'react';

import { FormContext } from 'components/Form';
import { Option } from 'components/Dropdown';
import ProjectContext from 'views/projects/ProjectContext';
import { FieldShowCondition } from '../../../../../_common/schema/FieldShowCondition';
import { FieldShowOnlyIfCondition } from '../../../../../_common/schema/FieldShowOnlyIfCondition';

/* eslint-disable react/display-name */
const fieldDependsOnOtherThan =
  (Component: React.ComponentType<any>) => (props: any) => {
    let newProps = props;

    if (!newProps?.field) {
      newProps = {
        ...newProps,
        field: newProps.role,
      };
    }

    const formContext = React.useContext(FormContext);
    const projectContext = React.useContext(ProjectContext);

    let show = true;
    if (newProps.editForm && newProps.field.editable === false) {
      newProps = {
        ...newProps,
        field: {
          ...newProps.field,
          disabled: true,
        },
      };
    }

    if (newProps.field.showOnlyIf) {
      newProps.field.showOnlyIf.forEach((condition: FieldShowCondition) => {
        if (!matchCondition(condition, formContext, projectContext)) {
          show = false;
        }
      });

      let isFieldRequired = newProps.field.required;

      newProps.field?.requiredIf?.forEach(
        (condition: FieldShowOnlyIfCondition) => {
          if (matchCondition(condition, formContext, projectContext)) {
            isFieldRequired = true;
          }
        },
      );

      newProps = {
        ...newProps,
        field: {
          ...newProps.field,
          required: isFieldRequired,
        },
      };

      if (!show) {
        // remove redundant field value
        if (formContext.values.fields) {
          if (formContext.values.fields[newProps.field.name] !== false) {
            formContext.values.fields[newProps.field.name] = null;
          }
        }

        return null;
      }
    }
    if (newProps.field.hideIf && newProps.field.hideIf.length > 0) {
      // TODO: Implement OR condition
      for (const conditionsList of newProps.field.hideIf) {
        let allMatches = true;
        conditionsList.forEach((condition: FieldShowCondition) => {
          if (!matchCondition(condition, formContext, projectContext)) {
            allMatches = false;
          }
        });

        if (allMatches) {
          return null;
        }
      }
    }

    if (newProps.field.filterOptions) {
      let relatedValue =
        formContext.values.fields?.[newProps.field.filterOptions.propertyName];

      if (typeof relatedValue === 'undefined') {
        relatedValue =
          formContext.values[newProps.field.filterOptions.propertyName];
      }
      if (typeof relatedValue === 'object') {
        relatedValue = relatedValue?.value;
      }

      if (relatedValue === true) {
        relatedValue = '1';
      }
      if (relatedValue === false) {
        relatedValue = '0';
      }
      if ('' + relatedValue in newProps.field.filterOptions.conditions) {
        const values = newProps.field.filterOptions.conditions[
          relatedValue
        ] as Option<string>[];
        newProps = {
          ...newProps,
          field: {
            ...newProps.field,
            values,
          },
        };
      } else {
        newProps = {
          ...newProps,
          field: {
            ...newProps.field,
            values: [],
          },
        };
      }
      if (newProps.field.advancedRadioValues.length > 0) {
        const values = newProps.field.values.map((item: any) => item.value);
        const advancedRadioValues = newProps.field.advancedRadioValues.filter(
          (item: any) => values.includes(item.value),
        );

        newProps = {
          ...newProps,
          field: {
            ...newProps.field,
            advancedRadioValues,
          },
        };
      }
    }

    return <Component {...newProps} />;
  };

export const matchCondition = (
  condition: FieldShowCondition,
  formContext: any,
  projectContext: any,
) => {
  let relatedValue = formContext.values[condition.propertyName];

  if (
    typeof relatedValue === 'undefined' &&
    typeof formContext.values.fields !== 'undefined'
  ) {
    relatedValue = formContext.values.fields[condition.propertyName];
  }

  if (projectContext.resource !== null) {
    if (
      typeof relatedValue === 'undefined' &&
      typeof projectContext.resource.fields !== 'undefined'
    ) {
      relatedValue = projectContext.resource.fields[condition.propertyName];
    }

    if (condition.propertyName === 'category_id') {
      relatedValue = projectContext.resource.category_id;
    }
  }

  if (relatedValue === true) {
    relatedValue = '1';
  }
  if (relatedValue === false) {
    relatedValue = '0';
  }
  if (relatedValue && typeof relatedValue === 'object' && relatedValue.value) {
    relatedValue = relatedValue.value;
  }
  if (
    typeof relatedValue === 'object' &&
    relatedValue !== null &&
    Array.isArray(relatedValue)
  ) {
    const temp = relatedValue.some((item: any) => {
      if (Array.isArray(condition.value)) {
        if (condition.value.includes(item.value)) {
          return true;
        }
        return false;
      } else {
        if (item.value == condition.value) {
          return true;
        }
        return false;
      }
    });
    return temp;
  }
  if (!condition?.value?.includes('' + relatedValue)) {
    return false;
  }

  return true;
};

export default fieldDependsOnOtherThan;
