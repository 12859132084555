import { Role } from "@contractool/schema";
import React, { FC } from "react";

import { AppContext } from "contexts";
import ProjectContext from "views/projects/ProjectContext";
import { Dropdown, Option } from "components/Dropdown";
import { translate } from "utils/translations";

const ProjectTeamRoleSelect: FC<{
    value?: string;
    onChange: (role: Role) => void;
}> = ({ onChange, value = null }) => {
    const { resource: project, teamMembers, workflow } = React.useContext(ProjectContext);

    const filledRoles = teamMembers.map(({ role }) => role);

    const roles = workflow.roles.filter(
        (role: Role) =>
            role.key === value ||
            (role.workflow === project.workflow &&
                role.key !== "assignee" &&
                role.key !== "requestor" &&
                !(role.singular && filledRoles.includes(role.key)))
    );

    const roleOptions: Option<string>[] = roles.map((role: Role) => ({
        value: role.key,
        label: role.label
    }));

    if (!roles) {
        return null;
    }

    const handleChange = (roleKey: string) => {
        let role = roles.find(({ key }: Role) => key === roleKey);
        if (role) {
            onChange(role);
        }
    };

    return (
        <Dropdown
            name={value ? "existing_member_role" : "new_member_role"}
            value={value}
            options={roleOptions}
            onChange={handleChange}
            hasError={false}
            autocomplete={true}
            placeholder={translate("Select role")}
        />
    );
};

const memoizedProjectTeamRoleSelect = React.memo(ProjectTeamRoleSelect);

export default memoizedProjectTeamRoleSelect;
