import React, { FC } from 'react';

import { Icon, Icons } from './Icon';

export type TVariation = 'success' | 'warning' | 'error' | 'info';

export interface IToastOptions {
  onClose?: () => void;
  variation: TVariation;
  icon?: Icons;
  message?: string;
  className?: string;
  closeToast?: () => void;
  children?: React.ReactNode;
}

const settings: {
  [key in TVariation]: {
    name: Icons;
    color: string;
    background: string;
  };
} = {
  success: {
    name: 'done',
    color: 'text-teal-700',
    background: 'bg-teal-100',
  },
  warning: {
    name: 'error',
    color: 'text-orange-600',
    background: 'bg-orange-100',
  },
  error: {
    name: 'close',
    color: 'text-red-700',
    background: 'bg-red-100',
  },
  info: {
    name: 'visibility',
    color: 'text-white',
    background: 'bg-blue-400',
  },
};

export const Toast: FC<IToastOptions> = ({
  variation,
  className,
  icon,
  message,
  closeToast,
  children,
}) => {
  const setting = settings[variation];

  return (
    <div
      className={`${className} flex items-center justify-between select-none`}
    >
      <div className="flex items-center">
        <div className="w-10 inline-block">
          <div
            className={`w-10 h-10 rounded-full inline-flex items-center justify-center ${setting.background}`}
          >
            <Icon
              name={icon ?? setting.name}
              size={5}
              className={`${setting.color}`}
            />
          </div>
        </div>
        <div className="w-full inline-block mx-5 text-gray-700">
          {message ? message : children}
        </div>
      </div>

      {closeToast && (
        <button
          onClick={closeToast}
          className="cursor-pointer focus:outline-none"
        >
          <Icon name="close" size={6} className="text-gray-600" />
        </button>
      )}
    </div>
  );
};
