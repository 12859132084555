import React, { ReactNode, FC, useState } from "react";
import { Button } from "./Button";
import { Modal } from "./Modal";
import { Message } from "./Message";
import { translate } from "utils/translations";

export const Confirmation: FC<{
    onConfirm: () => void;
    trigger: (params: { onClick: () => void }) => void;
    children?: ReactNode;
    heading: string;
    buttonText: string;
    color: "yellow" | "red";
    content?: ReactNode;
    button?: ReactNode;
}> = ({ onConfirm, trigger, children, heading, color, buttonText, content, button }) => {
    const [show, setShow] = useState(false);

    return (
        <>
            {trigger({ onClick: () => setShow(true) })}

            <Modal isOpen={show} onClose={() => {
            }} heading={heading} size="small">
                {color === "yellow" && children && <Message.Warning>{children}</Message.Warning>}
                {color === "red" && children && <Message.Error>{children}</Message.Error>}
                {content}
                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={() => setShow(false)}>
                        {translate("Cancel")}
                    </Button>

                    {button ?? (
                        <Button
                            color={color}
                            onClick={() => {
                                setShow(false);
                                onConfirm();
                            }}
                        >
                            {buttonText}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};
