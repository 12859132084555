import { Field } from 'formik';
import { Field as FieldWrapper } from 'components/Field';
import MultiselectDropdown2 from 'components/MultiselectDropdown2';
import { ReactNode } from 'react';
import { Option } from 'components/select';
import { getValue } from 'components/Form';

type TProps = {
  name: string;
  label: string;
  api?: string;
  apiParams?: object;
  legend?: string;
  helptext?: string;
  options?: Option<any>[];
  placeholder?: string;
  className?: string;
  required?: boolean;
  dropdownClassName?: string;
  autocomplete?: boolean;
  isMulti: boolean;
  extra?: { project_params: string[] };
  right?: ReactNode;
  defaultValue?: string;
  preselectsFirstOption?: boolean;
  role?: {
    key: string;
    value: string;
  };
  reset?: boolean;
};

const FormikSelect: React.FC<TProps> = ({
  name,
  label,
  api,
  apiParams,
  legend,
  helptext,
  className,
  required = false,
  options,
  isMulti,
  extra,
  right,
  placeholder,
  defaultValue,
  preselectsFirstOption,
  role,
  reset,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, meta, form }: any) => {
        let params: object | null = null;

        extra?.project_params?.map((key) => {
          const value = field?.value;
          if (!value) return;

          params = {
            ...params,
            [key]: value,
          };
        });

        // If the field is a string, convert it to an object
        let fieldValue = field?.value;
        if (fieldValue && typeof fieldValue === 'string') {
          fieldValue = { label: fieldValue, value: fieldValue };
        }

        // check if is static options, that if value exists in options
        // if no then reset field value
        if (options && fieldValue && !api && !reset) {
          if (isMulti) {
            // check if field can have multiple values
            fieldValue.map((value) => {
              const option = options.find(
                (option) => option.value === value.value,
              );
              if (!option) {
                form.setFieldValue(name, null);
              }
            });
          } else {
            // check if is object / array
            if (
              (Array.isArray(fieldValue) && fieldValue.length > 0) ||
              typeof fieldValue === 'object'
            ) {
              const option = options.find(
                (option) => option.value === fieldValue.value,
              );
              if (!option) {
                form.setFieldValue(name, null);
              }
            }
          }
        }
        return (
          <FieldWrapper
            {...field}
            className={className}
            label={label}
            legend={legend}
            helptext={helptext}
            errorMessage={meta.touched && meta.error}
            required={required}
            right={right}
          >
            <MultiselectDropdown2
              name={name}
              api={api}
              params={params}
              apiParams={apiParams || extra}
              onChange={(value) => {
                form.setFieldValue(name, value);
              }}
              value={fieldValue}
              options={options}
              isMulti={isMulti}
              placeholder={placeholder}
              defaultValue={defaultValue}
              preselectsFirstOption={preselectsFirstOption}
			  menuPortalTarget={true}
              {...props}
            />
          </FieldWrapper>
        );
      }}
    </Field>
  );
};

export default FormikSelect;
