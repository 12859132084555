import React from 'react';
import { format } from 'date-fns';
import { DayContentProps } from 'react-day-picker';

import { dayType } from '../utils';

const Day: React.FC<DayContentProps> = ({ date, activeModifiers }) => {
  const className = {
    outside: 'w-12 h-12 text-gray-500',
    disabled: 'w-12 h-12 text-gray-300',
    selected: 'w-12 h-12 bg-blue-700 text-white rounded',
    normal: 'w-12 h-12 hover:bg-blue-100 rounded',
    today: 'w-12 h-12 text-blue-700',
  };

  return (
    <div
      className={`flex items-center justify-center cursor-pointer ${
        className[dayType(activeModifiers)]
      }`}
	  role='option'
    >
      {format(date, 'd')}
    </div>
  );
};

export default Day;
