import React, {
  FC,
  ReactElement,
  MouseEvent as ReactMouseEvent,
  ReactNode,
} from 'react';
import { Icons, Icon } from './Icon';
import { Popper } from './Popper';
import { Placement } from 'popper.js';
import { cx } from 'utils/classnames';
import { createPortal } from 'react-dom';

export const Menu: FC<{
  placement?: Placement;
  autoClose?: boolean;
  className?: string;
  handle: Icons | ((props: { toggleMenu: () => void }) => ReactElement);
  children?: (props: { open: () => void; close: () => void }) => ReactElement;
}> = ({
  placement = 'bottom-start',
  handle,
  children,
  className,
  autoClose = true,
}) => {
  return (
    <Popper
      handle={({ ref, onClick }) => (
        <div ref={ref} data-testid="menu-handle">
          {typeof handle === 'string' ? (
            <button onClick={onClick}>
              <Icon name={handle} className="text-gray-600" size={6} />
            </button>
          ) : (
            handle({ toggleMenu: onClick })
          )}
        </div>
      )}
      placement={placement}
    >
      {({ ref, style, open, close }) =>
        createPortal(
          <div
            ref={ref}
            style={style}
            className={cx(className, 'bg-white mt-4 shadow rounded-xl')}
            data-testid="menu-content"
            onClick={() => autoClose ? close() : {}}
          >
            {typeof children === 'function'
              ? children({
                  open: () => {
                    open();
                  },
                  close: () => {
                    close();
                  },
                })
              : children}
          </div>,
          document.body,
        )
      }
    </Popper>
  );
};

export const MenuItem: FC<{
  icon: Icons;
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  nowrap?: boolean;
  children?: ReactNode;
}> = ({ icon, onClick, nowrap = false, children }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="w-full flex items-center leading-tighter transition rounded-lg p-5 bg-white hover:bg-gray-000 hover:border-gray-000 focus:outline-none focus:ring"
    >
      <Icon name={icon} size={5} className="text-gray-600 mr-3" />

      <span
        className="select-none text-gray-700"
        style={nowrap ? { whiteSpace: 'nowrap' } : undefined}
      >
        {children}
      </span>
    </button>
  );
};
