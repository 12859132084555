import React, { FC } from 'react';
import { cx } from 'utils/classnames';

export interface TextAreaProps {
  name: string;
  cols?: number;
  rows?: number;
  placeholder?: string;
  hasError?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
  defaultValue?: string;
  className?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (e) => void;
}

export const TextArea: FC<TextAreaProps> = ({
  name,
  onChange,
  hasError = false,
  className,
  ...rest
}) => {
  return (
    <textarea
      id={name}
      name={name}
      onChange={(e) => onChange && onChange(e.target.value)}
      className={cx(
        'pb-5 mt-5 w-full focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 resize-none leading-tight',
        hasError ? 'border-red-700' : 'border-gray-200 focus:border-blue-700',
        className,
      )}
      style={{ backgroundColor: 'transparent' }}
      {...rest}
    ></textarea>
  );
};
