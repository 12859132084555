export const isPageInRoute = (route: string, page: string) => {
  if (!route || !page) {
    return false;
  }
  return route?.split('/')?.[1] === page;
};

export const getMajorVersion = (version: string): string => {
  if (!version || typeof version !== 'string') {
    return '';
  }

  const versionParts = version.split('.');
  if (versionParts.length === 4) {
    return versionParts.slice(0, -1).join('.');
  }

  return '';
};

export const replaceNullTextValuesImmutable = (obj) => {
  // Function to deep clone objects to ensure immutability
  function deepClone(item) {
    if (Array.isArray(item)) {
      return item.map(deepClone);
    } else if (item !== null && typeof item === 'object') {
      return Object.keys(item).reduce((acc, key) => {
        acc[key] = deepClone(item[key]);
        return acc;
      }, {});
    } else {
      return item;
    }
  }

  // Start with a deep clone of the object
  const clonedObj = deepClone(obj);

  // Recursive function to replace null values
  function recurse(current) {
    if (Array.isArray(current)) {
      current.forEach((item) => recurse(item));
    } else if (current !== null && typeof current === 'object') {
      Object.keys(current).forEach((key) => {
        if (key === 'text' && (current[key] === null || current[key] == '')) {
          current[key] = ' '; // Replace null with empty string
        } else {
          recurse(current[key]);
        }
      });
    }
  }

  // Start the recursion from the cloned object
  recurse(clonedObj);

  return clonedObj;
};
