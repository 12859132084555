import { Toast, TVariation } from 'components/Toast';
import { Icons } from 'components/Icon';
import { TPosition } from 'components/Notifications';
import { translate } from 'utils/translations';
import { toast } from 'react-toastify';

const RaiseToast = (
  variation: TVariation,
  message = '',
  position: TPosition = 'bottom-center',
  icon: Icons = 'visibility',
  ttl = 6000,
) => {
  toast(<Toast variation={variation} icon={icon} message={message} />, {
    hideProgressBar: true,
    type: variation,
    icon: false,
    position: position,
    autoClose: ttl,
    closeButton: false,
  });
};

export default () => {
  const toaster =
    (variation: TVariation) =>
    (
      message: string,
      options: {
        position?: TPosition;
        icon?: Icons;
        closeTimeout?: number;
      } = {},
    ) =>
      RaiseToast(
        variation,
        translate(message),
        options.position,
        options.icon,
        options.closeTimeout,
      );

  return {
    success: toaster('success'),
    error: toaster('error'),
    warning: toaster('warning'),
    info: toaster('info'),
  };
};
