import { User } from '@contractool/schema';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import { AuthContext } from 'contexts';
import { Form } from 'components/Form';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { Layout } from './Layout';
import { useParams } from 'react-router-dom';

const initialValues = {
  name: '',
  email: '',
  tel: '',
};

export function InvitationPage() {
  const history = useHistory();
  const { login } = useContext(AuthContext);
  const { campaign } = useParams<{ campaign: string }>();

  const attemptLogin = useCallback((values) => {
    return http.post('/api/invitation', { ...values, campaign });
  }, [campaign]);

  const onSuccess = useCallback(
    ({ user, redirect_url }: { user: User, redirect_url: string }) => {
      login(user);
      history.push(redirect_url);
    },
    [history, login],
  );

  return (
    <Layout>
      <h1 className="text-3xl leading-tight font-semibold select-none mb-30 text-gray-800">
        Pozvánka do Cequence
      </h1>

      <Form
        initialValues={initialValues}
        submitOnEnter={true}
        onSubmit={attemptLogin}
        onSuccess={onSuccess}
        allowPristineSubmission={true}
        guard={false}
      >
        <Form.TextInput
          name="name"
          label="Meno"
          placeholder="Petr Novák"
          className="mb-6"
		  required
        />
        <Form.TextInput
          name="email"
          label="Pracovný email"
          placeholder="your@email.com"
          className="mb-6"
		  required
        />
        <Form.TextInput
          name="tel"
          label="Telefónne číslo"
          placeholder="+420 123 456 789"
          className="mb-6"
        />

        <Form.Submit className="w-full mt-30">
          {translate('Vstúpte')}
        </Form.Submit>
      </Form>
    </Layout>
  );
}
