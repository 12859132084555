import { Field } from 'formik';
import { Field as FieldWrapper } from 'components/Field';
import { TextInput, Type } from 'components/TextInput';

type TProps = {
  name: string;
  label: string;
  className?: string;
  autoFocus?: boolean;
  required?: boolean;
  type?: Type;
  readOnly?: boolean;
};

const FormikTextInput: React.FC<TProps> = ({
  name,
  label,
  className,
  autoFocus,
  required,
  type = 'text',
  readOnly,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, meta, form }: any) => (
        <FieldWrapper
          {...field}
          helptext={props.helptext}
          className={className}
          label={label}
          legend={props.legend}
          errorMessage={meta.touched && meta.error}
          required={required}
          readOnly={readOnly}
        >
          <TextInput
            {...field}
            onChange={(value) => {
              form.setFieldValue(name, value);
            }}
            hasError={meta.touched && meta.error}
            autoFocus={autoFocus}
            type={type}
            {...props}
          />
        </FieldWrapper>
      )}
    </Field>
  );
};

export default FormikTextInput;
