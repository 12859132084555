import { GuardContext } from 'contexts';
import { fieldDependsOnOtherThan } from 'features/form/field';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { isAllowedForUser } from 'utils/form';
import FormikTextInput from './FormikTextInput';
import { FieldTemplate } from '../../../../../_common/schema';
import FormikDatePicker from './FormikDatePicker';
import FormikSelect from './FormikSelect';
import FormikSwitch from 'components/form/formik/FormikSwitch';

function FormikCustomField({
  field,
  className,
  required,
  option,
  readOnly,
}: {
  field: FieldTemplate;
  option: string;
  className?: string;
  required?: boolean;
  readOnly?: boolean;
}) {
  const { user } = useContext(GuardContext);
  const history = useHistory();
  if (!field) {
    return <div>Missing field in fields.php</div>;
  }
  if (!isAllowedForUser(field, user)) {
    return null;
  }
  let right = null;
  if (field.extra && field.extra.link) {
    // right = <Link className="text-blue-600 hover:text-blue-300" to={field.extra.link.href}>{field.extra.link.label}</Link>
    right = (
      <a
        className="cursor-pointer text-blue-700 flex items-center mr-4 hover:text-blue-300"
        href={field.extra.link.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {field.extra.link.label}
      </a>
    );
  }

  if (field.side_link && field.side_link.url) {
    if (field.side_link.url.includes('https://')) {
      right = (
        <a
          className="cursor-pointer text-blue-700 flex items-center mr-4 hover:text-blue-300"
          href={field.side_link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {field.side_link.text}
        </a>
      );
    } else {
      right = (
        <Link
          to={`${
            field?.side_link?.url?.at(0) === '/'
              ? ''
              : history.location.pathname + '/'
          }${field.side_link.url}${history.location.search}`}
          data-cy={'field-link-' + field.name}
          className="cursor-pointer text-blue-700 flex items-center mr-4 hover:text-blue-300"
        >
          {field.side_link.text}
        </Link>
      );
    }
  }

  let require = field.required;
  if (required) {
    require = required;
  }

  let legend = '';
  if (field.legend) {
    legend = field.legend;
  }

  let helptext = '';
  if (field.helptext) {
    helptext = field.helptext;
  }

  switch (field.type) {
    case 'INPUT':
      return (
        <FormikTextInput
          key={field.name}
          name={option ? option + field.name : field.name}
          label={field.label}
          legend={field.legend}
          helptext={helptext}
          readOnly={readOnly}
          type={field.name.includes('email') ? 'email' : 'text'}
          className={className}
          required={required}
          {...field.props}
        />
      );
    case 'SELECT2':
      return (
        <FormikSelect
          {...field.props}
          key={field.name}
          name={option ? option + field.name : field.name}
          label={field.label}
          api={field.valuesEndpoint}
          options={field.values}
          isMulti={field.isMulti}
          legend={legend}
          helptext={helptext}
          placeholder={field.label}
          dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
          className={className}
          autocomplete
          required={require}
          extra={field.extra}
          right={right}
          reset={field.reset}
        />
      );

    case 'DATE':
      return (
        <FormikDatePicker
          key={field.name}
          name={option ? option + field.name : field.name}
          label={field.label}
          legend={legend}
          helptext={helptext}
          formatInput="dd MMM yyyy"
          className={className}
          required={require}
          since={field.extra && field.extra.since ? field.extra.since : null}
          readOnly={readOnly}
          {...field.props}
        />
      );
    case 'CHECKBOX':
      return (
        <FormikSwitch
          key={field.name}
          name={option ? option + field.name : field.name}
          label={field.label}
          className={className}
          {...field.props}
        />
      );

    default:
      return (
        <FormikTextInput
          key={field.name}
          name={option ? option + field.name : field.name}
          label={field.label}
          legend={field.legend}
          helptext={helptext}
          readOnly={readOnly}
          className={className}
          required={required}
          {...field.props}
        />
      );
  }
}

const DependentCustomFieldOnCard = fieldDependsOnOtherThan(FormikCustomField);

export default DependentCustomFieldOnCard;
