import { userPermissionRequest } from 'utils/wildcard';
import { SidebarItem } from './Sidebar';
import { translate } from 'utils/translations';
import {
  URL_INTEGRATION_LIST,
  URL_INTEGRATION_WORKFLOW,
} from '@constants/integration';

const SidebarLinks = ({ user, config }) => {
  return (
    <ul>
      {userPermissionRequest(user, 'project.view') ? (
        <SidebarItem icon="dashboard" to={'/projects'}>
          {translate('Projects')}
        </SidebarItem>
      ) : null}
      {userPermissionRequest(user, 'other.reports') ? (
        <SidebarItem icon="bar_chart" to="/reports">
          {translate('Reports')}
        </SidebarItem>
      ) : null}
      {userPermissionRequest(user, 'other.reports') ? (
        <SidebarItem icon="corporate" to="/reports-library">
          {translate('Reports Library')}
        </SidebarItem>
      ) : null}
      {userPermissionRequest(user, 'other.imports') ? (
        <SidebarItem icon="get_app" to="/imports">
          {translate('Imports')}
        </SidebarItem>
      ) : null}
      {userPermissionRequest(user, 'repository.view') &&
      config?.config?.repository == 'true' ? (
        <SidebarItem icon="description" to="/repository">
          {translate('Repository')}
        </SidebarItem>
      ) : null}
      {userPermissionRequest(user, 'other.documentLibrary') ? (
        <SidebarItem icon="description" to="/library">
          {translate('Documents Library')}
        </SidebarItem>
      ) : null}
      {userPermissionRequest(user, 'clauses.view') ? (
        <SidebarItem icon="description" to="/clauses">
          {translate('Clause library')}
        </SidebarItem>
      ) : null}
      {userPermissionRequest(user, 'suppliers.view') ? (
        <SidebarItem icon="contacts" to="/suppliers">
          {translate(config.config.supplier_label_plural)}
        </SidebarItem>
      ) : null}
      {userPermissionRequest(user, 'team.view') ? (
        <SidebarItem icon="people" to="/team">
          {translate('Team')}
        </SidebarItem>
      ) : null}
      {userPermissionRequest(user, 'other.viewNews') ? (
        <SidebarItem icon="feed" to="/news">
          {translate('News')}
        </SidebarItem>
      ) : null}
      {userPermissionRequest(user, 'other.settings') && (
        <SidebarItem
          icon="settings"
          to={
            user.super_admin ? URL_INTEGRATION_LIST : URL_INTEGRATION_WORKFLOW
          }
        >
          {translate('Settings')}
        </SidebarItem>
      )}

      {userPermissionRequest(user, 'ai.view') ? (
        <SidebarItem icon="lightbulb" to="/ai">
          {translate('AI')}
        </SidebarItem>
      ) : null}

      {/* ui lib should be accessible in dev environment
                                and CI as some cypress test run against it */}
      {(import.meta.env.MODE !== 'production' ||
        import.meta.env.VITE_APP_CI) && (
        <SidebarItem icon="select_all" to="/ui">
          Design System
        </SidebarItem>
      )}
    </ul>
  );
};

export default SidebarLinks;
