export const Logo = ({ className }) => {
  return (
    <svg
      width="246"
      height="48"
      viewBox="0 0 246 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6857 26.2857H0.400024C1.55102 38.4684 11.8087 48 24.2926 48C27.5299 48 30.6175 47.359 33.4354 46.1971C25.3867 42.8783 19.5382 35.3092 18.6857 26.2857Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M18.6857 21.7143C19.5382 12.6908 25.3867 5.12173 33.4354 1.80292C30.6175 0.640967 27.5299 0 24.2926 0C11.8087 0 1.55102 9.53158 0.400024 21.7143H18.6857Z"
        fill="#005CF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3222 29.7143C27.494 37.1995 22.1552 43.3083 15.1495 46.1971C17.9675 47.359 21.0551 48 24.2924 48C35.578 48 45.0443 40.2103 47.6079 29.7143H29.3222Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M47.5726 18.2857H29.2869C27.4587 10.8005 22.1199 4.69166 15.1143 1.80292C17.9322 0.640967 21.0198 0 24.2571 0C35.5428 0 45.009 7.78969 47.5726 18.2857Z"
        fill="#2979FF"
      />
      <path
        d="M92.1928 19.6175C91.3827 12.8988 86.2948 9.03516 79.5583 9.03516C71.8696 9.03516 66 14.4613 66 23.9783C66 33.467 71.7701 38.9215 79.5583 38.9215C87.0196 38.9215 91.5248 33.9641 92.1928 28.609L85.9679 28.5806C85.3852 31.6914 82.9407 33.4812 79.6578 33.4812C75.2378 33.4812 72.2391 30.1999 72.2391 23.9783C72.2391 17.9272 75.1952 14.4755 79.7004 14.4755C83.0686 14.4755 85.4989 16.4215 85.9679 19.6175H92.1928Z"
        fill="#1B1D1C"
      />
      <path
        d="M104.42 38.9499C109.821 38.9499 113.459 36.3221 114.312 32.2738L108.712 31.9045C108.101 33.5664 106.538 34.4329 104.52 34.4329C101.493 34.4329 99.5739 32.43 99.5739 29.1772V29.163H114.44V27.5011C114.44 20.0863 109.949 16.4215 104.179 16.4215C97.7548 16.4215 93.5907 20.9812 93.5907 27.7141C93.5907 34.6317 97.6979 38.9499 104.42 38.9499ZM99.5739 25.413C99.7018 22.9272 101.592 20.9386 104.278 20.9386C106.907 20.9386 108.726 22.8136 108.741 25.413H99.5739Z"
        fill="#1B1D1C"
      />
      <path
        d="M137.041 46.7056V16.7056H131.072V20.3704H130.802C129.991 18.5948 128.243 16.4215 124.591 16.4215C119.801 16.4215 115.751 20.1431 115.751 27.6431C115.751 34.9442 119.631 38.8789 124.605 38.8789C128.13 38.8789 129.963 36.8477 130.802 35.0295H130.986V46.7056H137.041ZM131.114 27.6147C131.114 31.5067 129.437 34.0636 126.524 34.0636C123.553 34.0636 121.933 31.4215 121.933 27.6147C121.933 23.8363 123.525 21.2369 126.524 21.2369C129.466 21.2369 131.114 23.7227 131.114 27.6147Z"
        fill="#1B1D1C"
      />
      <path
        d="M153.008 29.234C153.022 32.1602 151.018 33.6943 148.844 33.6943C146.556 33.6943 145.078 32.0891 145.064 29.5181V16.7056H139.009V30.5977C139.023 35.6971 142.008 38.8079 146.4 38.8079C149.682 38.8079 152.042 37.1175 153.022 34.5607H153.25V38.5238H159.062V16.7056H153.008V29.234Z"
        fill="#1B1D1C"
      />
      <path
        d="M171.262 38.9499C176.662 38.9499 180.301 36.3221 181.153 32.2738L175.554 31.9045C174.943 33.5664 173.379 34.4329 171.361 34.4329C168.334 34.4329 166.415 32.43 166.415 29.1772V29.163H181.281V27.5011C181.281 20.0863 176.79 16.4215 171.02 16.4215C164.596 16.4215 160.432 20.9812 160.432 27.7141C160.432 34.6317 164.539 38.9499 171.262 38.9499ZM166.415 25.413C166.543 22.9272 168.434 20.9386 171.12 20.9386C173.749 20.9386 175.568 22.8136 175.582 25.413H166.415Z"
        fill="#1B1D1C"
      />
      <path
        d="M188.885 25.9102C188.899 23.0977 190.576 21.4499 193.021 21.4499C195.451 21.4499 196.915 23.0408 196.901 25.7113V38.5238H202.955V24.6317C202.955 19.5465 199.971 16.4215 195.423 16.4215C192.182 16.4215 189.837 18.0124 188.857 20.555H188.601V16.7056H182.831V38.5238H188.885V25.9102Z"
        fill="#1B1D1C"
      />
      <path
        d="M214.808 38.9499C220.62 38.9499 224.273 35.5408 224.557 30.5266H218.844C218.488 32.8562 216.954 34.163 214.879 34.163C212.05 34.163 210.217 31.7908 210.217 27.6147C210.217 23.4954 212.065 21.1374 214.879 21.1374C217.096 21.1374 218.517 22.6005 218.844 24.7738H224.557C224.301 19.7312 220.478 16.4215 214.779 16.4215C208.156 16.4215 204.063 21.0096 204.063 27.6999C204.063 34.3335 208.085 38.9499 214.808 38.9499Z"
        fill="#1B1D1C"
      />
      <path
        d="M235.98 38.9499C241.381 38.9499 245.019 36.3221 245.872 32.2738L240.273 31.9045C239.661 33.5664 238.098 34.4329 236.08 34.4329C233.053 34.4329 231.134 32.43 231.134 29.1772V29.163H246V27.5011C246 20.0863 241.509 16.4215 235.739 16.4215C229.315 16.4215 225.151 20.9812 225.151 27.7141C225.151 34.6317 229.258 38.9499 235.98 38.9499ZM231.134 25.413C231.262 22.9272 233.152 20.9386 235.838 20.9386C238.468 20.9386 240.287 22.8136 240.301 25.413H231.134Z"
        fill="#1B1D1C"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="16.984"
          y1="26.2857"
          x2="16.984"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005CF5" />
          <stop offset="1" stopColor="#2979FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="31.3025"
          y1="29.7143"
          x2="31.3025"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2979FF" />
          <stop offset="1" stopColor="#005CF5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
