import { Project, User } from '@contractool/schema';

const extractWildcards = (permission: string) => {
  const arrayOfPermissions: string[] = [];

  const dividedPermissions = permission.split('.');

  dividedPermissions.forEach((val, index) => {
    let value;
    if (!arrayOfPermissions[index - 1]) {
      value = `${val}.*`;
    } else {
      if (index !== dividedPermissions.length - 1) {
        value = `${arrayOfPermissions[index - 1].substring(
          0,
          arrayOfPermissions[index - 1].length - 2,
        )}.${val}.*`;
      } else {
        value = `${arrayOfPermissions[index - 1].substring(
          0,
          arrayOfPermissions[index - 1].length - 2,
        )}.${val}`;
      }
    }
    arrayOfPermissions.push(value);
  });

  return arrayOfPermissions;
};

export const checkPermission = (can: any, permission: string, user: User) => {
  let permissions: string[] = extractWildcards(permission);

  if (
    'delegator_permissions' in user &&
    Object.keys(user.delegator_permissions)?.length > 0
  ) {
    if (
      user.delegator_permissions[permission]
    ) {
      return user.delegator_permissions[permission];
    }
  }

  if(typeof can[permission] !== 'undefined'){
      return can[permission];
  }

  permissions = permissions.sort((a: any, b: any) => {
    const aCount = a.split('.').length - 1;
    const bCount = b.split('.').length - 1;

    if (aCount === bCount) {
      return 0;
    }

    return aCount < bCount ? 1 : -1;
  });

  for (const p of permissions) {
    if (typeof can[p] !== 'undefined') {
      return can[p];
    }
  }

  const has = permissions.some((val) => Object.keys(can).includes(val));

  return has;
};

export const permissionRequest = (
  project: Project | User,
  permission: string,
): boolean => {
  let permissions: string[] = extractWildcards(permission);

  // check if project.delegator_permission has more keys
  const can = 'can' in project ? project.can : project?.permissions;

  // first check delegations permission
  if (
    'delegator_permissions' in project &&
    Object.keys(project.delegator_permissions)?.length > 0
  ) {
    if (typeof project.delegator_permissions[permission] !== 'undefined') {
      if (
        can &&
        typeof can[permission] !== 'undefined' &&
        can[permission] === true
      ) {
        return true;
      }
      return project.delegator_permissions[permission];
    }
  }

  if (can) {
    //First check if user has permission on its own
    if (can[permission]) {
      return can[permission];
    } else {
      //If user does not have a permission, check for permission in delegator permissions
      if (
        'delegator_can' in project &&
        Object.keys(project.delegator_can)?.length > 0
      ) {
        // console.log('Project ' + 'Permission: ' + permission + ' Can:' + project.delegator_can[permission],);
        return project.delegator_can[permission];
      }
      // if user doesn't have permission check if has permission in delegations
      if (
        'delegator_permissions' in project &&
        Object.keys(project.delegator_permissions)?.length > 0
      ) {
        return project.delegator_permissions[permission];
      }

      if (typeof can[permission] == 'undefined') {
        return false;
      } else {
        return can[permission];
      }
    }
  }

  permissions = permissions.sort((a: any, b: any) => {
    const aCount = a.split('.').length - 1;
    const bCount = b.split('.').length - 1;

    if (aCount === bCount) {
      return 0;
    }

    return aCount < bCount ? 1 : -1;
  });

  for (const p of permissions) {
    if (typeof can[p] !== 'undefined') {
      return can[p];
    }
  }

  const has = permissions.some((val) => Object.keys(can).includes(val));

  return has;
};

export const userPermissionRequest = (
  user: User,
  permission: string,
): boolean => {
  return permissionRequest(user, permission);
};
