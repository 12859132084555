import React, {ReactNode} from 'react';

export default class OutsideClick extends React.Component<{
    children: ReactNode;
    onOutsideClick: (event: MouseEvent) => void;
}> {
    isClickedInside = false;

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    handleDocumentClick = (event: MouseEvent) => {
        if (this.isClickedInside) {
            this.isClickedInside = false;

            return;
        }

        this.props.onOutsideClick(event);
    };

    render() {
        return React.cloneElement(<div>{this.props.children}</div>, {
            onClickCapture: () => (this.isClickedInside = true),
        });
    }
}
