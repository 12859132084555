import { useState } from 'react';
import { translate } from 'utils/translations';
import useToggle from 'hooks/useToggle';
import { SupplierContactModal } from 'components/form/SupplierContactModal';
import { Contact } from 'views/suppliers/Contacts/Contact';

const initialValues = {
  name: '',
  email: '',
  phone: '',
  is_default: false,
};

export const ContactList = ({
  contacts,
  contactModalVisible,
  contactModalVisController,
  onChange,
}) => {
  const [selectedContact, setSelectedContact] = useState<number | null>(null);
  const [editContactModal, editContactModalVisController] = useToggle(false);

  return (
    <>
      {contacts?.length > 0 && (
        <div>
          <div className="block leading-none select-none text-gray-600">
            {translate('Contacts')}
          </div>
          <div className="mt-6 mb-2">
            <table className="w-full table-fixed">
              <tbody>
                {contacts.map((contact: any, index: number) => (
                  <Contact
                    key={`contacts${index}`}
                    index={index}
                    contact={contact}
                    onEdit={(index) => {
                      setSelectedContact(index);
                      editContactModalVisController.on();
                    }}
                    editContactModal={editContactModal}
                    onDelete={(index) => {
                      const updatedContacts = contacts.filter(
                        (_, i) => i !== index,
                      );
                      onChange(updatedContacts);
                    }}
                    selectedContact={selectedContact}
                    onCancel={() => {
                      editContactModalVisController.off();
                    }}
                    onSuccess={(newValues) => {
                      const updatedContacts = contacts.map((c, i) =>
                        i === index ? newValues : c,
                      );

                      onChange(updatedContacts);
                      editContactModalVisController.off();
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <SupplierContactModal
        label={translate('Add contact')}
        isOpen={contactModalVisible}
        initialValues={initialValues}
        onCancel={contactModalVisController.off}
        onSuccess={(newValues) => {
          if (contacts.length > 0) {
            onChange([...contacts, newValues]);
          } else {
            onChange([newValues]);
          }

          contactModalVisController.off();
        }}
      />
    </>
  );
};
