import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { useBigLoader } from 'components/Loader';
import { translate } from 'utils/translations';

export const AppVersionModal: FC<{ beVersion: string }> = ({ beVersion }) => {
  const { start: startBig } = useBigLoader();
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const reload = (e) => {
    e.preventDefault();
    startBig(translate('Loading new version...'));
    localStorage.setItem('_app_version', beVersion);
    window.location.reload();
  };

  const news = () => {
    startBig(translate('Loading new version...'));
    localStorage.setItem('_app_version', beVersion);
    window.location.href = '/#/news';
    window.location.reload();
  };

  const hasLastNumberChanged = (oldVersion, newVersion) => {
    const oldParts = oldVersion.split('.');
    const newParts = newVersion.split('.');
    return (
      oldParts.slice(0, -1).join('.') === newParts.slice(0, -1).join('.') &&
      oldParts[oldParts.length - 1] !== newParts[newParts.length - 1]
    );
  };

  useEffect(() => {
    const localVersion = localStorage.getItem('_app_version');
    if (localVersion && hasLastNumberChanged(localVersion, beVersion)) {
      setShowSettingsModal(true);
    }
  }, [beVersion]);

  if (showSettingsModal) {
    return (
      <Modal
        onClose={reload}
        heading={translate('Cequence settings have been changed!')}
      >
        <div className="card-body text-gray-800">
          <p className="mb-4 font-medium">
            {translate(
              'Cequence settings have been changed. By confirming this popup, the page will be reloaded.',
            )}
          </p>
        </div>
        <div className="card-footer text-center mt-8">
          <Button onClick={reload} color="white">
            {translate('Continue')}
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal onClose={reload} heading={translate('New version available!')}>
      <div className="card-body text-gray-800">
        <p className="mb-4 font-medium">
          {translate('A new version of Cequence is now available.')}
        </p>

        <p>
          {translate(
            'We update the app regularly to make it faster and more reliable for you. To see what\'s new check our "News section" or just continue with using Cequence.',
          )}
        </p>
      </div>

      <div className="card-footer text-center mt-8">
        <Button onClick={news} color="blue" className="mr-8">
          {translate("Show me what's new")}
        </Button>
        <Button onClick={reload} color="white">
          {translate('Continue')}
        </Button>
      </div>
    </Modal>
  );
};
