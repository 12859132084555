import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Icon, Icons } from '../Icon';
import { userPermissionRequest } from 'utils/wildcard';
import { AppContext, GuardContext } from 'contexts';
import { translate } from 'utils/translations';
import { cx } from 'utils/classnames';
import { Logo } from 'components/Logo';
import { useExpanded } from 'hooks/useExpanded';
import SidebarLinks from './SidebarLinks';
import { useWindowSize } from '@uidotdev/usehooks';

const ANIMATION_DURATION = 0.3;

const sidebarStyle = {
  transition: `width ${ANIMATION_DURATION}s`,
};

const sidebarLabelStyle = {
  transition: `opacity ${ANIMATION_DURATION}s`,
};

export function Sidebar() {
  const [expanded, toggleExpanded] = useExpanded();
  const { user } = React.useContext(GuardContext);
  const { config } = React.useContext(AppContext);
  const size = useWindowSize();

  return (
    <div
      className={`hidden md:block border-r shrink-0 h-full overflow-hidden ${
        expanded ? 'w-72' : 'w-20'
      }`}
      style={sidebarStyle}
    >
      <div className="flex flex-col h-full w-72">
        <div className="flex items-center shrink-0 border-b h-20 px-6">
          <div onClick={() => toggleExpanded()} className="cursor-pointer">
            {expanded && (
              <Icon name="dehaze" className="text-gray-600" size={8} />
            )}
            {!expanded && (
              <Icon
                name="menu_open"
                className="text-gray-600 rotate-180"
                size={8}
              />
            )}
          </div>
          <Link
            to="/projects"
            className={cx(
              'hidden md:inline-block',
              expanded ? 'opacity-100' : 'opacity-0',
            )}
            style={sidebarLabelStyle}
          >
            {size.width > 768 && <Logo className="ml-4 w-36" />}
          </Link>
        </div>

        <div className="h-full flex flex-col justify-between overflow-y-auto p-6">
          {userPermissionRequest(user, 'project.create') ? (
            <ul className="text-gray-600">
              <SidebarItem icon="add" to="/projects/new">
                {translate('New Project')}
              </SidebarItem>
            </ul>
          ) : (
            ''
          )}

          <div className="flex-1 flex items-center">
            <SidebarLinks user={user} config={config} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function SidebarItem<T extends Icons, C>({
  icon,
  to,
  children,
}: {
  icon: T;
  to: string;
  children: C;
}) {
  return (
    <li className="py-6">
      <NavLink
        to={to}
        className="flex items-center text-gray-600 hover:text-blue-700 focus:text-blue-700 focus:outline-none"
        activeClassName="text-blue-700"
      >
        <div className="flex justify-center w-8">
          <Icon name={icon} size={6} />
        </div>

        <div className="ml-6 leading-tighter select-none">{children}</div>
      </NavLink>
    </li>
  );
}
