import { useHelpers, useKeymap, useRemirrorContext } from '@remirror/react';
import { useCallback } from 'react';

import { translate } from 'utils/translations';

type Props = {
  onSubmit: (json: any) => void;
  isSubmitAllowed: boolean;
};

const SendButton: React.FC<Props> = ({ onSubmit, isSubmitAllowed }) => {
  const { getJSON } = useHelpers();
  const { clearContent } = useRemirrorContext();

  const onSave = useCallback(
    ({ state }) => {
      if (isSubmitAllowed) {
        onSubmit(getJSON(state));
        clearContent();
        // Prevents any further key handlers from being run.
        return true;
      }
    },
    [getJSON, isSubmitAllowed],
  );

  useKeymap('Enter', onSave);

  return (
    <div className="p-4">
      <button
        className="focus:outline-none text-blue-700 hover:text-blue-400"
        onClick={onSave}
        type="submit"
        disabled={!isSubmitAllowed}
      >
        {translate('Send')}
      </button>
    </div>
  );
};

export default SendButton;
