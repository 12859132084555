import { Field } from 'formik';
import { Field as FieldWrapper } from 'components/Field';
import { DatePicker } from 'components/DatePicker';

type TProps = {
  name: string;
  label: string;
  className?: string;
  required?: boolean;
  since?: Date;
  until?: Date;
  formatInput?: string | ((date: string) => string);
  readOnly?: boolean;
};

const FormikDatePicker: React.FC<TProps> = ({
  name,
  label,
  className,
  required,
  since,
  until,
  formatInput,
  readOnly,
}) => {
  return (
    <Field name={name}>
      {({ field, meta, form }: any) => (
        <FieldWrapper
          {...field}
          className={className}
          label={label}
          errorMessage={meta.touched && meta.error}
          required={required}
        >
          <DatePicker
            {...field}
            onChange={(value) => form.setFieldValue(name, value)}
            since={since}
            until={until}
            formatInput={formatInput}
            readOnly={readOnly}
          />
        </FieldWrapper>
      )}
    </Field>
  );
};

export default FormikDatePicker;
