import { Button } from 'components/Button';
import { translate } from 'utils/translations';
import * as Sentry from '@sentry/react';
import { GuardContext } from 'contexts';
import React, { useContext } from 'react';
import { Icon } from 'components/Icon';

interface Props {
  errorComponent?: boolean;
  children: React.ReactNode;
  title?: string;
}

export const ErrorGuard: React.FC<Props> = ({
  children,
  errorComponent,
  title,
}) => {
  const { user } = useContext(GuardContext);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => {
        if (errorComponent) {
          return (
            <ErrorComponent
              error={error}
              resetErrorBoundary={resetError}
              title={title}
            />
          );
        }
        return (
          <ErrorPageContent error={error} resetErrorBoundary={resetError} />
        );
      }}
      onReset={() => {
        window.location.reload();
      }}
      beforeCapture={(scope) => {
        scope.setTag(
          'appVersion',
          localStorage.getItem('_app_version') || 'unknown',
        );
        if (user) {
          scope.setUser({
            email: user.email,
            username: user.name,
            id: user.id,
          });
        }
      }}
    >
      {children || null}
    </Sentry.ErrorBoundary>
  );
};

const ErrorComponent = ({ error, resetErrorBoundary, title }) => {
  return (
    <div className="flex justify-center items-center space-y-2 flex-col p-10 h-full">
      <h1 className="text-sm font-semibold" title={title || ''}>
        {translate('An error occured')}
      </h1>
      <div className="text-center text-sm">
        <p>{translate('We apologize for the inconvenience')}.</p>
      </div>
      <div className="space-x-5 pt-2 flex flex-row">
        <button className="text-blue-600 text-sm flex items-center" onClick={resetErrorBoundary}>
          <Icon className='inline-block' name="refresh" size={4} />
          {translate('Try again')}
        </button>
        <button className='text-sm flex items-center' onClick={() => window.location.replace('#/projects')}>
          <Icon className='inline-block' name="arrow_forward" size={4} />
          {translate('Dashboard')}
        </button>
      </div>
    </div>
  );
};

const ErrorPageContent = ({ error, resetErrorBoundary }) => {
  return (
    <div className="error-page flex justify-center items-center space-y-5 flex-col h-full">
      <Icon name="error" size={12} />
      <h1 className="text-xl font-semibold">{translate('An error occured')}</h1>
      <div className="text-center">
        <p>{translate('We apologize for the inconvenience')}.</p>
        <p>
          {translate(
            'Our engineers are aware of this issue and are working to resolve it promptly',
          )}
          .
        </p>
      </div>

      <Button
        icon="arrow_forward"
        onClick={() => {
          window.location.replace('#/projects');
          window.location.reload();
        }}
      >
        {translate('Continue to dashboard')}
      </Button>
    </div>
  );
};
