import React, { useContext, useEffect, useState } from 'react';
import {
  FloatingWrapper,
  MentionAtomNodeAttributes,
  useMentionAtom,
} from '@remirror/react';
import { User } from '@contractool/schema';
import { translate } from 'utils/translations';
import { useRequest } from 'hooks/useRequest';
import { AppContext } from 'contexts';
import { getWorkflow } from 'hooks/workflows';
import { cx } from 'utils/classnames';

type Props = {
  content: any;
};

const Mention: React.FC<Props> = ({ content }) => {
  const { assessmentWorkflow } = useContext(AppContext);
  const workflow = getWorkflow(assessmentWorkflow);
  const [options, setOptions] = useState<MentionAtomNodeAttributes[]>([]);

  const { state, getMenuProps, getItemProps, indexIsSelected } = useMentionAtom(
    {
      items: options,
    },
  );

  const [response] = useRequest<User[]>('api/suppliers', [], {
    params: { phrase: state?.query?.full },
  });

  useEffect(() => {
    if (!state) {
      return;
    }

    if (state.name === 'at') {
      const atoms = content?.content?.[0]?.content.filter(
        (item: any) => item.type === 'mentionAtom',
      );
      const hashes = atoms.filter((item: any) => item.attrs.name === 'hash');
      const lastHash = hashes[hashes.length - 1];
      const lastHashValue = lastHash?.attrs?.id;
      let items = [];
      switch (lastHashValue) {
        case 'supplier':
        case 'default__supplier':
          if (response?.data) {
            items = response.data.map((user) => ({
              id: user.name,
              label: user.name,
            }));
            setOptions(items);
          } else {
            items = response.map((user) => ({
              id: user.name,
              label: user.name,
            }));
            setOptions(items);
          }

          break;

        default: {
          const field = workflow.fields.meta_data.filter(
            (meta) => meta.name === lastHashValue,
          )?.[0];
          if (field?.values?.length > 0 && Array.isArray(field?.values)) {
            items = field.values.map((item) => ({
              id: item.value,
              label: item.label,
            }));
            setOptions(items);
          } else {
            setOptions([]);
          }
          break;
        }
      }
    }

    if (state.name === 'hash') {
      const meta = workflow?.fields.meta_data;
      const tags = [
        ...meta.map((meta) => ({ label: meta.label, id: meta.name })),
      ];
      if (tags.find((item) => item.label.includes('Supplier'))) {
        tags.find((item) => item.label.includes('Supplier')).label =
          '🏢 Supplier';
      }
      const filter = tags.filter((tag) =>
        tag.label.toLowerCase().includes(state.query.full),
      );

      setOptions(filter);
    }
  }, [state, workflow, response]);

  const enabled = Boolean(state);

  return (
    <FloatingWrapper
      positioner="cursor"
      useFloatingPortal={true}
      enabled={enabled}
      placement="bottom-start"
      containerClass="bg-white rounded-lg shadow-lg  w-[350px]"
    >
      <div
        {...getMenuProps()}
        className="suggestions py-2 max-h-96 overflow-y-auto"
      >
        {enabled && options.length > 0 ? (
          options.map((item, index) => {
            const isHighlighted = indexIsSelected(index);

            return (
              <div
                key={item.id}
                className={cx(
                  isHighlighted && 'bg-gray-000 ',
                  'flex items-center text-gray-700 w-full py-2 px-5 hover:bg-gray-000 cursor-pointer',
                )}
                {...getItemProps({
                  item: item,
                  index,
                })}
              >
                {item.label}
              </div>
            );
          })
        ) : (
          <div className="text-gray-700 pl-4 pr-4">
            {translate('No items available')}
          </div>
        )}
      </div>
    </FloatingWrapper>
  );
};

export default Mention;
