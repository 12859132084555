import { useEffect, useState } from 'react';

const decode = <T>(value: string | null): T | null => {
  try {
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
};

const encode = <T>(value: T): string | null => {
  try {
    return JSON.stringify(value);
  } catch (error) {
    console.error('Error encoding JSON:', error);
    return null;
  }
};

// useLocalStorage hook
const useLocalStorage = <T>(
  key: string,
  defaultState?: T,
): [
  T | undefined,
  React.Dispatch<React.SetStateAction<T | undefined>>,
  () => void | Promise<boolean>,
] => {
  const [value, setValue] = useState<T | undefined>(
    () => decode<T>(localStorage.getItem(key)) || defaultState,
  );

  useEffect(() => {
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      const encodedValue = encode(value);
      if (encodedValue !== null) {
        localStorage.setItem(key, encodedValue);
      } else {
        console.error(
          'Error encoding JSON; not saving value to local storage.',
        );
      }
    }
  }, [key, value]);

  const clearData = async () => {
    setValue(undefined);
    localStorage.removeItem(key);
    return localStorage.getItem(key) === null;
  };

  return [value, setValue, clearData];
};

export { useLocalStorage };
