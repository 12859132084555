import { History } from 'history';

type NavigateProps = {
  history: History;
  path: string;
  replace?: boolean;
  preserveQuery?: boolean;
};

export const navigateTo = ({
  history,
  path,
  replace = false,
  preserveQuery = false,
}: NavigateProps): void => {
  const search = preserveQuery ? history.location.search : '';
  const fullPath = `${path}${search}`;

  replace ? history.replace(fullPath) : history.push(fullPath);
};