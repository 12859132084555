import { AuthContext, PusherContext } from 'contexts';
import useToasts from 'hooks/useToasts';
import React, { FC, useEffect } from 'react';
import { ToastContainer as ToastifyContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToastPayload {
  message: string;
  status: number;
  closeTimeout?: number;
}

// PositionType should include all values from positions
export type TPosition =
  | 'top-center'
  | 'top-right'
  | 'bottom-center'
  | 'bottom-right';

const Notifications: FC = () => {
  const pusher = React.useContext(PusherContext);
  const { user } = React.useContext(AuthContext);
  const { success, error } = useToasts();

  console.log('PusherChannel', user);
  useEffect(() => {
    if (pusher && pusher.private && user && user.id) {
      const channel = 'user.' + user.id;
      pusher.private(channel).listen('.success', (payload: ToastPayload) => {
        const toastParams = {
          closeTimeout: payload.closeTimeout,
        };
        if (payload.status === 200) {
          success(payload.message, toastParams);
        } else {
          error(payload.message, toastParams);
        }
      });
    }

    return () => {
      if (pusher && pusher.private && user && user.id) {
        const channel = 'user.' + user.id;
        pusher.leave(channel);
      }
    };
  }, [pusher, user]);

  return <ToastifyContainer />;
};

export default Notifications;
