import { Field } from 'formik';
import { Field as FieldWrapper } from 'components/Field';
import { Switch } from 'components/Switch';

type TProps = {
  name: string;
  label: string;
  className?: string;
  autoFocus?: boolean;
  required?: boolean;
};

const FormikSwitch: React.FC<TProps> = ({
  name,
  label,
  className,
  required,
}) => {
  return (
    <Field name={name}>
      {({ field, meta, form }: any) => (
        <FieldWrapper
          {...field}
          className={className}
          label={label}
          errorMessage={meta.touched && meta.error}
          required={required}
        >
          <Switch
            {...field}
            onChange={(value) => {
              form.setFieldValue(name, value);
            }}
            hasError={meta.touched && meta.error}
          />
        </FieldWrapper>
      )}
    </Field>
  );
};

export default FormikSwitch;