import { createContext } from 'react';
import { AssessmentWorkflowState } from '@contractool/schema';

type CequenceContextProp = {
  loaded: boolean; // todo: use React.Suspense
  config: any;
  setAssessmentWorkflow: (state: string) => void;
  assessmentWorkflow: AssessmentWorkflowState;
  refresh: () => void;
};

const AppContext = createContext({} as CequenceContextProp);

export default AppContext;
