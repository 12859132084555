import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Icon } from '../Icon';
import { ReactComponent as LogoSmall } from './../../assets/cequenceSmall.svg';

import { userPermissionRequest } from 'utils/wildcard';
import { AppContext, GuardContext } from 'contexts';
import { translate } from 'utils/translations';
import { SidebarItem } from './Sidebar';
import { Logo } from 'components/Logo';
import { useExpanded } from 'hooks/useExpanded';
import SidebarLinks from './SidebarLinks';
import { useMediaQuery } from 'react-responsive';

const ANIMATION_DURATION = 0.3;

const sidebarStyle = {
  transition: `width ${ANIMATION_DURATION}s`,
};

export function MobileSidebar() {
  const [expanded, toggleExpanded, setExpanded] = useExpanded();
  const { user } = useContext(GuardContext);
  const { config } = useContext(AppContext);
  const isMobile = useMediaQuery({ query: '(max-width: 911px)' });


  const history = useHistory();

  useEffect(() => {
	if(isMobile){
    	setExpanded('collapsed');
	}
  }, [history.location.pathname]);

  return (
    <div
      className={`md:hidden fixed  shrink-0 left-0 top-0 overflow-hidden ${
        expanded ? 'bg-white z-50 border-r h-screen' : 'w-32 '
      }`}
      style={sidebarStyle}
    >
      <div className="flex flex-col">
        <div className="flex items-center shrink-0 border-b border-r h-20 px-6">
          <div onClick={() => toggleExpanded()} className="cursor-pointer">
            {expanded && (
              <Icon name="dehaze" className="text-gray-600" size={8} />
            )}
            {!expanded && (
              <Icon
                name="menu_open"
                className="text-gray-600 rotate-180"
                size={8}
              />
            )}
          </div>
          <Link to="/projects">
            {expanded ? (
              <Logo className="ml-4 w-36" />
            ) : (
              <LogoSmall className="ml-4 w-36" />
            )}
          </Link>
        </div>

        <div
          className={`h-full flex flex-col justify-between overflow-y-auto p-6 ${
            expanded ? '' : 'hidden -z-10'
          }`}
        >
          {userPermissionRequest(user, 'project.create') ? (
            <ul className="text-gray-600">
              <SidebarItem icon="add" to="/projects/new">
                {translate('New Project')}
              </SidebarItem>
            </ul>
          ) : (
            ''
          )}

          <div className="flex-1 flex items-center">
            <SidebarLinks user={user} config={config} />
          </div>
        </div>
      </div>
    </div>
  );
}
