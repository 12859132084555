import React, { FC, useContext } from 'react';
import { Supplier } from '../../../../../_common/schema';
import useToasts from 'hooks/useToasts';
import { Menu, MenuItem } from '../../../components/Menu';
import { Confirmation } from '../../../components/Confirmation';
import { http } from '../../../utils/http';
import { translate } from 'utils/translations';
import { userPermissionRequest } from 'utils/wildcard';
import { GuardContext } from 'contexts';

export const SupplierMenu: FC<{
  supplier: Supplier;
  onRefresh: () => void;
  onClose: () => void;
}> = ({ supplier, onRefresh, onClose }) => {
  const { success } = useToasts();
  const { user } = useContext(GuardContext);

  let menuItems = [];
  if (userPermissionRequest(user, 'suppliers.delete')) {
    menuItems.push(
      <Confirmation
        onConfirm={() => {
          http.delete(supplier.delete_url).then(() => {
            onClose();
            onRefresh();
            success(
              `${translate('Supplier :title was deleted', {
                title: supplier.title,
              })}.`,
            );
          });
        }}
        trigger={({ onClick }) => (
          <MenuItem
            icon="remove_circle"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            {translate('Delete')}
          </MenuItem>
        )}
        heading={translate('Delete supplier')}
        buttonText={translate('Yes, delete')}
        color="red"
      >
        {translate(
          "Are you sure you want to delete supplier :title? You can't undo this action.",
          { title: supplier.title },
        )}
      </Confirmation>,
    );
  }
  if (menuItems.length === 0) {
    return <></>;
  }
  return <Menu handle="more_vert">{menuItems.map((item) => item)}</Menu>;
};
