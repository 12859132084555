import React, {
  ChangeEventHandler,
  FC,
  InputHTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Icon } from '../Icon';
import {
  BoldExtension,
  HeadingExtension,
  ItalicExtension,
  MentionAtomExtension,
  UnderlineExtension,
  PlaceholderExtension,
  ImageExtension,
  DropCursorExtension,
} from 'remirror/extensions';
import { CountExtension } from '@remirror/extension-count';
import {
  ThemeProvider,
  Remirror,
  useRemirror,
  EditorComponent,
} from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';
import { InvalidContentHandler } from 'remirror';
import { translate } from 'utils/translations';
import Mention from './SearchDropdown';
import './style.scss';
import { cx } from 'utils/classnames';
import { replaceNullTextValuesImmutable } from 'utils/general';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  value: string;
  onClear?: () => void;
  autoFocus?: boolean;
  className?: string;
  onChange: (value: string) => void;
}

const Search: FC<SearchProps> = ({
	value,
	placeholder,
	autoFocus,
	className,
	onChange,
	onClear,
	onKeyUp,
  ...props
}) => {
  return (
    <div className={cx('relative h-14 select-none', className)}>
      <input
        type="search"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            onClear && onClear();
          }
        }}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        autoFocus={autoFocus}
        className="w-full h-14 py-4 pr-4 pl-9 focus:outline-none border-b border-gray-100 focus:border-blue-700 appearance-none placeholder-gray-500"
        {...props}
      />

      <div className="absolute left-0 top-0 w-7 h-14 flex items-center justify-center">
        <Icon name="search" className="text-gray-500" size={6} />
      </div>
    </div>
  );
};

export const EnhancedSearchNew: FC<{
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onClear?: () => void;
  onKeyUp?: (event: any) => void;
  type: 'input' | 'textarea';
}> = ({ value, placeholder, onChange, onClear, onKeyUp, type }) => {
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [content, setContent] = useState(null);
  const normalizedValue = useMemo(() => replaceNullTextValuesImmutable(value), [value]);

  const onError: InvalidContentHandler = useCallback(
    ({ json, invalidContent, transformers }) => {
      // Automatically remove all invalid nodes and marks.
      console.log('InvalidContentHandler', json, invalidContent);
	  return replaceNullTextValuesImmutable(json);
    },
    [],
  );
  const { manager, state } = useRemirror({
    extensions: () => [
      new HeadingExtension({ levels: [1, 2, 3] }),
      new BoldExtension({ weight: 'bold' }),
      new ItalicExtension(),
      new UnderlineExtension(),
      new PlaceholderExtension({
        placeholder: translate(
          'Type here, use @ to mention somebody or # to tag',
        ),
      }),
      new MentionAtomExtension({
        matchers: [
          {
            name: 'at',
            char: '@',
            matchOffset: 0,
            mentionClassName: 'text-gray-700, font-bold',
          },
          {
            name: 'hash',
            char: '#',
            matchOffset: 0,
            mentionClassName: 'text-gray-700, font-bold',
          },
        ],
      }),
      new CountExtension({}),
      new ImageExtension({ enableResizing: true }),
      new DropCursorExtension(),
    ],
    content: normalizedValue,
    selection: 'end',
    stringHandler: 'html',
    onError,
  });

  const typeClass = {
    input: 'input',
    textarea: 'textarea',
  };

  const onFocus = useCallback(() => {
    setInputFocused(true);
  }, []);

  return (
    <AllStyledComponent>
      <ThemeProvider>
        <Remirror
          manager={manager}
          initialContent={state}
          classNames={[typeClass[type]]}
          onChange={(parameter) => {
            onChange?.(parameter.helpers.getJSON());
            setContent(parameter.helpers.getJSON());
          }}
          onFocus={onFocus}
        >
          <Mention content={content} />
          <div className="flex items-center bg-gray-000 rounded-lg">
            <div className="w-full relative pl-10">
              <div className="absolute left-0 top-0 w-18 flex items-center justify-center h-full">
                <Icon name="search" className="text-gray-500" size={6} />
              </div>
              <EditorComponent />
            </div>
          </div>
        </Remirror>
      </ThemeProvider>
    </AllStyledComponent>
  );

  return (
    <div className="relative h-14 select-none ">
      <input
        type="search"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            onClear && onClear();
          }
        }}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        className="w-full h-14 py-4 pr-4 pl-14 focus:outline-none focus:border-blue-700 appearance-none placeholder-gray-500 bg-gray-200 rounded-xl"
      />

      <div className="absolute left-0 top-0 w-14 h-14 flex items-center justify-center">
        <Icon name="search" className="text-gray-500" size={6} />
      </div>
    </div>
  );
};

const EnhancedSearch = React.memo(Search);

///export const EnhancedSearchNew = React.memo(SearchNew);

export default EnhancedSearch;
