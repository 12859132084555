const transformObject = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  const transformedObj: { [key: string]: any } = {};
  for (const key in obj) {
    const value = obj[key];
    if (
      typeof value === 'object' &&
      value !== null &&
      'value' in value &&
      'label' in value
    ) {
      transformedObj[key] = value.value;
    } else {
      transformedObj[key] = value;
    }
  }
  return transformedObj;
};

export default transformObject;


export const transformObjects: (objects: {
	[key: string]: any;
}, props?: any) => { [key: string]: any } = (
	objects,
	props,
) => {
  const transformedObj: { [key: string]: any } = [];
  for (const key in objects) {
    const value = objects[key];
    if (Object.keys(value).length > 2) {
      if (value.value && value.label) {
        if (props?.formatOptionLabel) {
          transformedObj[key] = {
            value: value.value,
            label: value.label,
            ...value,
          };
        } else {
          transformedObj[key] = {
            value: value.value,
            label: value.label,
          };
        }
      } else {
        if (props?.formatOptionLabel) {
          transformedObj[key] = {
            value: value.id,
            label: value.label,
            ...value,
          };
        } else {
          transformedObj[key] = {
            value: value.id,
            label: value.label,
          };
        }
      }
    }
  }
  if (Object.keys(transformedObj).length == 0) {
    return objects;
  }
  return transformedObj;
};
