import React, {ReactNode, FC, ReactElement} from 'react';

export const Switch: FC<{
    name?: string;
    value: boolean;
    onChange: (value: boolean) => void;
    children?: ReactNode;
    className?: string;
    readOnly?: boolean;
}> & {Multiple: typeof Multiple} = ({name, value, children, className, readOnly, onChange}) => {
    const cursorClass = readOnly ? 'cursor-auto' : 'cursor-pointer';

    return (
        <label
            className={`flex items-center ${cursorClass} select-none ${className}`}
            htmlFor={name}
        >
            <input
                type="checkbox"
                id={name}
                name={name}
                checked={value}
                onChange={(e) => !readOnly && onChange(e.target.checked)}
                className="hidden"
            />

            <div
                className={`w-14 h-8 p-1 rounded-full transition ${
                    value ? 'bg-blue-700 hover:bg-blue-800' : 'bg-gray-200 hover:bg-gray-300'
                }`}
            >
                <div
                    className="w-6 h-6 rounded-full bg-white transition"
                    style={{
                        transform: value ? 'translateX(100%)' : 'translateX(0%)',
                    }}
                />
            </div>

            {children && <div className="pl-3">{children}</div>}
        </label>
    );
};

function Multiple<T>({
    name,
    value,
    toKey,
    items,
    children,
    onChange,
    className,
}: {
    name: string;
    value: T[];
    items: T[];
    onChange: (values: T[]) => void;
    children: (item: T, element: FC) => ReactElement;
    toKey: (item: T) => string;
    className?: string;
}): ReactElement {
    return (
        <div className={className}>
            {items.map((item) => {
                return children(item, ({children}) => {
                    return (
                        <Switch
                            name={`${name}[${toKey(item)}]`}
                            value={value.some((v) => toKey(v) === toKey(item))}
                            onChange={(bool) => {
                                if (bool) {
                                    onChange([...value, item]);
                                } else {
                                    onChange(value.filter((v) => toKey(v) !== toKey(item)));
                                }
                            }}
                        >
                            {children}
                        </Switch>
                    );
                });
            })}
        </div>
    );
}

Switch.Multiple = Multiple;
