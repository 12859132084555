import { route } from '../routeListWithPermissionRestrictions';
import { checkPermission } from '../../../utils/wildcard';
import { User } from '@contractool/schema';

const getMatchingRoute: (
  pathname: string,
  routeList: route[],
  base: string,
) => route | undefined = (pathname, routeList, base) =>
  routeList.find(({ path }) => pathname.startsWith(`${base}/${path}`));

const checkAuthorization: (
  pathname: string,
  user: User,
  userPermissions: any,
  routeList: route[],
  base?: string,
) => boolean = (pathname, user, userPermissions, routeList, base = '') => {
  const matchingRoute = getMatchingRoute(pathname, routeList, base);

  if (!matchingRoute) {
    // when no matching route
    // return false;
    return Boolean(base); // todo: once list has all routes within, this line can be just "return false;"
  }
  if (matchingRoute.permissions === null) {
    // when permissions === null, this route is accessible with no permissions
    return true;
  }
  if (
    !(
      matchingRoute.permissions.length > 0 &&
      matchingRoute.permissions.reduce(
        // when route has permissions and all passes checking
        (authorized, routePermission) =>
          authorized && checkPermission(userPermissions, routePermission, user),
        true,
      )
    )
  ) {
    return false;
  }
  if (matchingRoute.children) {
    // if has children
    return checkAuthorization(
      pathname,
      user,
      userPermissions,
      matchingRoute.children,
      matchingRoute.path,
    );
  }

  return true;
};

export default checkAuthorization;
