import { useLocalStorage } from './useLocalStorage';

export function useExpanded(): [
  boolean,
  () => void,
  (value: 'collapsed' | 'expanded') => void,
] {
  const [value, setValue] = useLocalStorage('_sidebar');

  const toggle = () => {
    setValue(value === 'expanded' ? 'collapsed' : 'expanded');
  };

  if (value === undefined) {
    return [true, toggle, setValue];
  }

  return [value === 'expanded', toggle, setValue];
}
