import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AppContext } from 'contexts';
import { FormRenderer } from 'components/Formio';
import { getTranslations } from 'utils/translations';
import { DEFAULT_LANGUAGE } from 'data';
import useToggle from 'hooks/useToggle';
import { ContactList } from './Contacts/ContactsList';
import { NewContact } from './Contacts/NewContactButton';

type TContact = {
  title: string;
  address_street?: string;
  address_city?: string;
  address_zip?: string;
  address_state?: string;
  fields: any;
  contacts: any[];
};

interface SupplierFormProps {
  create?: boolean;
  supplier: TContact;
  onSubmit: (data: any) => void;
}

const SupplierForm = forwardRef<HTMLFormElement, SupplierFormProps>(
  function SupplierForm({ create, supplier, onSubmit }, ref) {
    const { config } = useContext(AppContext);

    const [contacts, setContacts] = useState([]);
    const contactsRef = useRef(contacts);

    const onFormReady = useCallback((form) => {
      ref.current = form;
    }, []);

    const fields = {
      display: 'form',
      components: [
        ...config.supplier_default_fields,
        ...config.supplier_fields,
      ],
    };

    const [formData, setFormData] = useState({
      ...supplier.fields,
      ...supplier,
    });

    useEffect(() => {
      setFormData({
        ...supplier.fields,
        ...supplier,
      });
    }, [supplier]);

    const handleFormChange = (schema) => {
      const updatedData = schema.data;
      if (schema.changed) {
        setFormData(updatedData);
      }
    };

    useEffect(() => {
      contactsRef.current = contacts;
    }, [contacts]);

    const [contactModalVisible, contactModalVisController] = useToggle(false);

    const transformSubmission = (formData: any, contact) => {
      const mappedData = {
        ...formData,
        fields: {},
        contacts: contact || [],
      };

      config.supplier_fields.forEach((field: any) => {
        if (formData[field.key]) {
          mappedData.fields[field.key] = formData[field.key];
        }
      });

      return mappedData;
    };

    return (
      <>
        <FormRenderer
          ref={ref}
          submission={{ data: formData }}
          form={{
            ...fields,
            settings: { editing: true },
          }}
          options={{
            language: DEFAULT_LANGUAGE,
            i18n: { [DEFAULT_LANGUAGE]: getTranslations() },
          }}
          saveDraft={true}
          onChange={handleFormChange}
          formReady={onFormReady}
          onSubmit={(formSubmission: any) => {
            const transformedData = transformSubmission(
              formSubmission.data,
              contactsRef.current,
            );
            onSubmit(transformedData);
          }}
        />

        {create && (
          <>
            <ContactList
              contacts={contacts}
              contactModalVisible={contactModalVisible}
              contactModalVisController={contactModalVisController}
              onChange={(values) => {
                setContacts(values);
              }}
            />

            <NewContact
              onClick={() => {
                contactModalVisController.on();
              }}
            />
          </>
        )}
      </>
    );
  },
);

export default SupplierForm;
