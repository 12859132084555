import { Delegation } from '@contractool/schema';
import { Message } from 'components/Message';
import { translate } from 'utils/translations';

export const getFilterDelegations = (
  delegations: Delegation[],
  id: number | string,
): Delegation => {
  if (delegations?.length > 0) {
    let delegationItems: Delegation[] = [];
    if (Number(id)) {
      delegationItems = delegations
        .filter((item) => item != null)
        .filter((item) => item.delegator.id == id);
    } else if (String(id)) {
      delegationItems = delegations
        .filter((item) => item != null)
        .filter((item) => item.delegator.name == id);
    }

    if (delegationItems?.length > 0) {
      return delegationItems?.[0];
    }
  }
};

export const getBackupDelegation = (
  delegations: Delegation[],
  id: number | string,
): Delegation => {
  if (delegations.length > 0) {
    let delegationItems: Delegation[] = [];
    if (Number(id)) {
      delegationItems = delegations
        .filter((item) => item != null)
        .filter((item) => item.backup.id == id);
    } else if (String(id)) {
      delegationItems = delegations
        .filter((item) => item != null)
        .filter((item) => item.backup.name == id);
    }

    if (delegationItems?.length > 0) {
      return delegationItems?.[0];
    }
  }
};

export const getActiveDelegator = (
  delegations: Delegation[],
  id: number | string,
): Delegation => {
  if (delegations?.length > 0) {
    let delegationItems: Delegation[] = [];

    if (Number(id)) {
      delegationItems = delegations
        .filter((item) => item != null && item?.is_active)
        .filter((item) => item.delegator.id == id);
    } else if (String(id)) {
      delegationItems = delegations
        .filter((item) => item != null && item?.is_active)
        .filter((item) => item.delegator.name == id);
    }

    if (delegationItems?.length > 0) {
      return delegationItems?.[0];
    }
  }

  return null;
};

export const getActiveBackup = (
  delegations: Delegation[],
  id: number | string,
): Delegation => {
  if (delegations.length > 0) {
    let delegationItems: Delegation[] = [];
    if (Number(id)) {
      delegationItems = delegations
        .filter((item) => item != null && item?.is_active)
        .filter((item) => item.backup.id == id);
    } else if (String(id)) {
      delegationItems = delegations
        .filter((item) => item != null && item?.is_active)
        .filter((item) => item.backup.name == id);
    }
    if (delegationItems?.length > 0) {
      return delegationItems?.[0];
    }
  }
  return null;
};

export const getActiveDelegation = (
  delegations: Delegation[],
  fromDate?: string,
) => {
  if (delegations.length > 0) {
    const delegationItems = delegations.filter(
      (item) =>
        item != null &&
        item?.is_active &&
        (fromDate ? fromDate >= item.starts_at : true),
    );

    if (delegationItems?.length > 0) {
      return delegationItems?.[0];
    }
  }
  return null;
};

export const renderDelegationInfo = (delegation: Delegation) => (
  <>
    <strong>{delegation?.delegator.name}</strong> {translate('set their status to Away from')}{' '}
    <strong>{delegation?.starts_at}</strong> {translate('until')}{' '}
    <strong>{delegation?.ends_at}</strong> {translate('and would like to you to take over their current ongoing projects and approval tasks.')}{' '}
  </>
);

export const renderNotificationBadge = (length: number) => (
  <span className="inline-flex items-center justify-center w-6 h-6 text-xs font-semibold bg-blue-700 text-white rounded-full">
    {length || 1}
  </span>
);

export const getStatusMessage = (delegations: Delegation[]) => {
  if (delegations.length > 0) {
    if (delegations?.[0]?.is_active == false) {
      switch (delegations?.[0]?.state) {
        case 'pending':
          return (
            <div className="mb-6">
              <Message.Warning>Delegation Status: Pending</Message.Warning>
            </div>
          );

        case 'approved':
          return (
            <div className="mb-6">
              <Message.Success>Delegation Status: Approved</Message.Success>
            </div>
          );

        case 'denied':
          return (
            <div className="mb-6">
              <Message.Error>Delegation Status: Denied</Message.Error>
            </div>
          );

        default:
          return null;
      }
    }
    switch (delegations?.[0]?.state) {
      case 'approved':
        return (
          <div className="mb-6">
            <Message.Success>Delegation Status: Active</Message.Success>
          </div>
        );
      default:
        return null;
    }
  }
};
