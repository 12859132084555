import React, { useContext, useCallback, useState, ReactNode } from 'react';
import { Icon } from '../Icon';
import { AuthContext, GuardContext, PusherContext } from 'contexts';
import { Profile } from 'views/Profile';
import { MenuItem, Menu } from '../Menu';
import { Avatar } from '../Avatar';
import { useRequest } from 'hooks/useRequest';
import { Notification } from '@contractool/schema';
import { Link, NavLink, Route, Switch, useHistory } from 'react-router-dom';
import { http } from '../../utils/http';
import { translate } from 'utils/translations';
import { IconCircle } from '../IconCircle';
import DelegationBadge from 'components/DelegationBadge';
import ReactTooltip from 'react-tooltip';
import NavbarContext from 'contexts/NavbarContext';
import { MobileSidebar } from './MobileSidebar';
import { useMediaQuery } from 'react-responsive';
import DOMPurify from 'dompurify';
import { sanitizeOptions } from 'utils/sanitizeOptions';

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export function Navbar() {
  const { renderItem } = useContext(NavbarContext);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <div className="h-full flex items-center justify-between px-7 overflow-hidden">
      {isTabletOrMobile ? <Search /> : renderItem || <Search />}

      <div className="flex justify-between items-center md:w-72 space-x-5 md:space-x-0">
        <NotificationCenter />

        <UserArea />
      </div>
    </div>
  );
}

function Search() {
  return (
    <div className="flex flex-1 items-center select-none">
      <MobileSidebar />
    </div>
  );
}

function NotificationCenter() {
  const [notifications, { refresh, meta }] = useRequest<
    Notification[],
    { has_unread: boolean }
  >('/api/notifications', [], { params: { page: 1, per_page: 18 } });

  const [subscribed, setSubscribed] = useState(false);

  const history = useHistory();

  const { user } = useContext(GuardContext);
  const pusher = useContext(PusherContext);

  if (!subscribed && pusher && pusher.private) {
    const channel = `users.${user.id}`;
    pusher.private(channel).notification(() => {
      refresh();
    });
    setSubscribed(true);
  }

  return (
    <Menu
      autoClose={true}
      handle={({ toggleMenu }) => (
        <div
          onClick={toggleMenu}
          className="flex items-center select-none cursor-pointer"
        >
          <div className="relative">
            <Icon name="notifications" className="text-gray-500" size={8} />
            {meta?.has_unread && (
              <span className="flex absolute h-2 w-2 top-0 right-0">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
              </span>
            )}
          </div>
          <p className="ml-1 text-gray-600" data-cy-notifications>
            {translate('Notifications')}
          </p>
        </div>
      )}
    >
      {() => (
        <div className="w-screen md:w-160">
          <div className="p-6 border-b">
            <div className="flex justify-between items-center">
              <h1 className="text-lg">{translate('Notifications')}</h1>

              {meta?.has_unread && (
                <button
                  className="text-right text-blue-700"
                  onClick={() => {
                    http.delete('/api/notifications').then(refresh);
                  }}
                >
                  {translate('Mark all as read')}
                </button>
              )}
            </div>
          </div>

          <div className="lg:max-h-192 md:max-h-96 max-h-66 overflow-y-auto">
            {notifications.map((notification) => {
              const isDelegatedNotification = Boolean(
                notification.message?.extra?.delegator_name,
              );
              return (
                <div
                  key={notification.id}
                  onClick={() => {
                    http.post(notification.read_url).then(refresh);
					// get query params
					const searchParams = history?.location?.search;

                    window.location.href = notification?.url  + searchParams.toString();
                  }}
                >
                  <div
                    className={classNames(
                      'w-full hover:bg-blue-000 cursor-pointer',

                      !notification.read && 'bg-blue-050',
                    )}
                  >
                    <div className="flex items-center border-b mx-8">
                      <div className="w-12 mr-4">
                        {isDelegatedNotification ? (
                          <span
                            data-tip={`${translate('On behalf of')} ${
                              notification.message?.extra?.delegator_name
                            }`}
                          >
                            <ReactTooltip />
                            <IconCircle
                              name="delegatee"
                              circleSize={10}
                              iconSize={10}
                              color="blue"
                            />
                          </span>
                        ) : (
                          <IconCircle
                            name="alarm"
                            circleSize={10}
                            iconSize={5}
                            color="orange"
                          />
                        )}
                      </div>
                      <div>
                        <p
                          className="mb-2 mt-6 text-gray-600 break-words"
						  style={{ wordBreak: 'break-word' }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              notification.message.title,
                              sanitizeOptions(),
                            ),
                          }}
                        ></p>
                        <div className="text-sm mb-6 text-gray-600">
                          {notification.created_ago}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <NavLink
            to="/notifications"
            className="flex justify-center items-center my-6 cursor-pointer"
          >
            <Icon
              name="arrow_downward"
              className="text-center text-blue-700"
              size={6}
            />
            <p className="text-center text-blue-700 ml-2">
              {translate('Show all')}
            </p>
          </NavLink>
        </div>
      )}
    </Menu>
  );
}

function UserArea() {
  const { logout } = useContext(AuthContext);
  const { user } = useContext(GuardContext);

  const history = useHistory();

  const signOut = useCallback(() => {
    http.delete('/api/auth/logout').then(() => {
      logout();
    });
  }, [logout]);

  return (
    <>
      <div
        aria-label="User section"
        className="select-none flex items-center cursor-pointer"
      >
        <DelegationBadge user={user} />

        <Menu
          handle={({ toggleMenu }) => (
            <div
              onClick={toggleMenu}
              className="flex items-center cursor-pointer"
            >
              <Avatar user={user} className="w-8 h-8" />

              <Icon
                name="keyboard_arrow_down"
                className="text-gray-600 ml-1"
                size={6}
              />
            </div>
          )}
        >
          <Link
            to={{
              pathname: `${history.location.pathname}/profile/general`,
              state: { prevLocation: history.location.pathname },
			  search: history.location.search
            }}
          >
            <MenuItem icon="person">{translate('Profile')}</MenuItem>
          </Link>
          <MenuItem icon="exit_to_app" onClick={signOut}>
            {translate('Sign out')}
          </MenuItem>
        </Menu>
      </div>

      <Switch>
        <Route path={'*/profile'}>
          <Profile />
        </Route>
      </Switch>
    </>
  );
}
