import { Project, TeamMember } from "@contractool/schema";
import { createContext } from "react";
import { Workflow } from "../../../../_common/schema/Workflow";

const ProjectContext = createContext<{
    resource: Project,
    workflow: Workflow,
    teamMembers: TeamMember[],
    refresh: () => void// todo: remove refresh, add CRUD here
}>({
    resource: null as unknown as Project,
    workflow: null as unknown as Workflow,
    teamMembers: null as unknown as TeamMember[],
    refresh: () => {
    }
});

export default ProjectContext;
