import { useState } from 'react';
import { isEmpty } from 'lodash';

export const CEQUENCE_LS_KEY = 'cequence';

const useCequenceStorage = <T>(
  key: string,
  initialValue?: T | (() => T),
): [T, (state: T) => void, () => void | Promise<boolean>] => {
  const storage = window.localStorage.getItem(CEQUENCE_LS_KEY);
  if (!storage && initialValue && !isEmpty(initialValue)) {
    window.localStorage.setItem(
      CEQUENCE_LS_KEY,
      JSON.stringify({
        timestamp: new Date(),
        [key]: initialValue,
      }),
    );
  }

  const [state, setState] = useState(() => {
    const storage = window.localStorage.getItem(CEQUENCE_LS_KEY) || '{}';
    return JSON.parse(storage)?.[key] || initialValue;
  });

  const clearData = async () => {
    localStorage.removeItem(key);
    return localStorage.getItem(key) === null;
  };

  const persistState = (newState: T): void => {
    const previousState = JSON.parse(
      window.localStorage.getItem(CEQUENCE_LS_KEY) || '{}',
    );
    const tickets = Object.keys(previousState);

    const nextState = {
      ...tickets.reduce((o, k) => ({ ...o, [k]: previousState[k] }), {}),
      timestamp: new Date(),
      [key]: newState,
    };
    setState(newState);
    window.localStorage.setItem(CEQUENCE_LS_KEY, JSON.stringify(nextState));
  };

  return [state, persistState, clearData];
};

export { useCequenceStorage };
