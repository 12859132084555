import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import FocusTrap from 'focus-trap-react';
import { Popper, Manager, Reference } from 'react-popper';
import { format } from 'date-fns';
import moment from 'moment';

import { TextInput } from '../../TextInput';
import DatePicker from './DatePicker';
import { formatValue, getDate } from '../utils';

const KEY_BACKSPACE = 8;
const KEY_ESCAPE = 27;

type Props = {
  name: string;
  id?: string;
  value: string | null;
  placeholder?: string;
  onChange: (date: string | null) => void;
  onBlur?: () => void;
  since?: string;
  until?: string;
  hasError?: boolean;
  formatInput?: string | ((date: string) => string);
  placement?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
  readOnly?: boolean;
  autoFocus?: boolean;
  defaultOpen?: boolean;
};

const Container: React.FC<Props> = ({
  name,
  id,
  value,
  placeholder,
  onChange,
  onBlur,
  since,
  until,
  formatInput,
  hasError = false,
  placement = 'bottom-start',
  readOnly,
  autoFocus,
  defaultOpen = false,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [currentPlacement, setCurrentPlacement] = useState(placement);

  const popperRef = useRef<HTMLElement>(null);
  const handleRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (handleRef.current) {
      const rect = handleRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const distanceFromBottom = windowHeight - (rect.top + rect.height);

      setCurrentPlacement(distanceFromBottom < 350 ? 'auto' : 'bottom-start');
    }
  }, [window.innerHeight]);

  const closePopper = () => {
    setOpen(false);
    handleRef?.current?.focus();
  };

  return (
    <Manager>
      <Reference innerRef={handleRef}>
        {({ ref }) => (
          <div ref={ref} onClick={() => !readOnly && setOpen(true)}>
            <TextInput
              name={id || name}
              placeholder={placeholder}
              icon="date_range"
              defaultValue={formatValue(formatInput, value)}
              readOnly={true}
              onKeyDown={(e) => {
                if (e.keyCode === KEY_BACKSPACE || e.keyCode === KEY_ESCAPE) {
                  onChange(null);
                }
              }}
              onBlur={onBlur}
              hasError={hasError}
			  autoFocus={autoFocus}
            />
          </div>
        )}
      </Reference>
      {open &&
        createPortal(
          <Popper
            innerRef={popperRef}
            placement={currentPlacement}
            modifiers={{
              flip: { enabled: false },
              preventOverflow: { enabled: false },
            }}
			
          >
            {({ ref, style }) => (
              <FocusTrap
                active
                focusTrapOptions={{
                  initialFocus: false,
                  allowOutsideClick: true,
                  clickOutsideDeactivates: true,
                  onDeactivate: closePopper,
                  fallbackFocus: handleRef.current,
                }}
              >
                <div ref={ref} style={style} data-cy={`date-picker-${name}`}>
                  <DatePicker
                    value={value ? getDate(value) : null}
                    onChange={(date) => {
                      if (date && since) {
                        if (moment(date) < moment(since)) {
                          return;
                        }
                      }
                      onChange(date ? format(date, 'y-MM-dd') : null);
                      closePopper();
                    }}
                    isOpen={open}
                    since={since}
                    until={until}
                  />
                </div>
              </FocusTrap>
            )}
          </Popper>,
          document.body,
        )}
    </Manager>
  );
};

export default Container;
