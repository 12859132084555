import { translate } from 'utils/translations';
import { ContactAvatar } from '../SuppliersEdit';
import { Icon } from 'components/Icon';
import { SupplierContactModal } from 'components/form/SupplierContactModal';

export const Contact = ({
  contact,
  index,
  editContactModal,
  selectedContact,
  onEdit,
  onDelete,
  onCancel,
  onSuccess,
}) => {
  return (
    <tr>
      <td className="pb-4 w-14">
        <ContactAvatar contact={{ name: contact.name, id: index }} />
      </td>
      <td className="pb-4 pr-4 truncate">{contact.name}</td>
      <td className="pb-4 truncate text-gray-600">{contact.email}</td>
      <td className="pb-4 pl-4 text-gray-600">{contact.phone}</td>
      {contact.is_default ? (
        <td className="pb-4 pl-4 text-gray-600">{translate('Default')}</td>
      ) : (
        <td className="pb-4 pl-4 text-gray-600" />
      )}
      <td className="pb-4 pr-4 w-10 text-right">
        <div className="flex justify-end space-x-4">
          <button type="button" onClick={() => onEdit(index)}>
            <Icon name="edit" size={6} className="text-gray-600" />
          </button>
          <button type="button" onClick={() => onDelete(index)}>
            <Icon name="delete" size={6} className="text-gray-600" />
          </button>
          {editContactModal && selectedContact === index && (
            <SupplierContactModal
              key={`contacts${index}`}
              label={translate('Save')}
              isOpen={editContactModal}
              initialValues={contact}
              onCancel={onCancel}
              onSuccess={(newValues) => onSuccess(newValues)}
            />
          )}
        </div>
      </td>
    </tr>
  );
};
