import { FC, useContext } from 'react';
import { FieldTemplate, SupplierContact } from '../../../../_common/schema';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { translate } from 'utils/translations';
import { AppContext } from 'contexts';
import { Formik } from 'formik';
import { string } from 'yup';
import FormikCustomField from './formik/FormikCustomField';
import FormikTextInput from './formik/FormikTextInput';
import FormikSwitch from './formik/FormikSwitch';

const emailSchema = string().email().required();
const telSchema = string()
  .matches(/^[0-9]+$/, 'Invalid phone number')
  .required();

export const SupplierContactModal: FC<{
  label: string;
  initialValues: SupplierContact;
  onCancel: () => void;
  onSuccess: (contact: SupplierContact) => void;
  isOpen?: boolean;
}> = ({ label, initialValues, onSuccess, onCancel, isOpen }) => {
  const { config } = useContext(AppContext);
  return (
    <Modal
      heading={label}
      size="small"
      onClose={onCancel}
      portalClassName="supplier-contact"
      isOpen={isOpen}
    >
      <Formik
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = 'Required';
          }
          if (!values.name) {
            errors.name = 'Required';
          }
          config.supplier_poc_fields.forEach((field: FieldTemplate) => {
            if (field.required && !values[field.name]) {
              errors[field.name] = 'Required';
            }
          });
          if (!emailSchema.isValidSync(values.email)) {
            errors.email = 'Invalid email';
          }
          if (
            values.phone !== null &&
            !telSchema.isValidSync(values.phone) &&
            values.phone !== ''
          ) {
            errors.phone = 'Invalid phone number';
          }

          return errors;
        }}
        initialValues={initialValues}
        onSubmit={(values) => {
          onSuccess(values);
        }}
      >
        {({ handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <FormikTextInput
              name={'name'}
              label={translate('Name ')}
              className="mb-6"
              required
            />
            <FormikTextInput
              name={'email'}
              label={translate('Email')}
              className="mb-6"
              required
            />

            <FormikTextInput
              name={'phone'}
              label={translate('Phone')}
              className="mb-6"
              type="tel"
            />

            <FormikSwitch
              name={'is_default'}
              label={translate('Default')}
              className="mb-6"
            />

            {config.supplier_poc_fields.map(
              (field: FieldTemplate, index: number) => {
                return (
                  <FormikCustomField
                    key={field.name + index}
                    field={field}
                    className="mb-6"
					option="fields."
                  />
                );
              },
            )}
            <Modal.Footer className="flex justify-between">
              <Button color="white" onClick={onCancel}>
                {translate('Cancel')}
              </Button>

              <Button
                onClick={(e) => handleSubmit(e)}
                type="submit"
                disabled={!isValid}
              >
                {label}
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
