import React from 'react';

const highlightWord = (
  item: string,
  input: string,
  highlightClass: string,
  showExtra?: boolean,
  extra_property?: { extraTop?: string; extraBottom?: string },
) => {
  if (showExtra) {
	if(extra_property && extra_property.extraTop && extra_property.extraBottom) {
    return (
      <>
        <span className="block">{extra_property?.extraTop}</span>
        <span className="block text-gray-500">{extra_property?.extraBottom}</span>
      </>
    );
	}
  } else {
	    if (input) {
      const lowercaseItem = item.toLowerCase();
      const lowercaseInput = input.toLowerCase();

      const index = lowercaseItem.indexOf(lowercaseInput);
      if (index >= 0) {
        const word = item.slice(index, index + lowercaseInput.length);
        const firstPart = item.slice(0, index);
        const lastPart = item.slice(index + lowercaseInput.length);

        return (
          <>
            {firstPart}
            <span className={highlightClass}>{word}</span>
            {lastPart}
          </>
        );
      }
    }
  }

  return item;
};

export default highlightWord;
