import { ReactElement } from 'react';
import { UserGroup } from '@contractool/schema';

export function UserGroupChip({ group }: { group: UserGroup }): ReactElement {
  return (
    <div
      className={`px-3 py-2 rounded-full text-sm flex text-center items-center leading-none select-none ${groupColor(
        group,
      )} inline m-2`}
    >
      {group.label}
    </div>
  );
}

function groupColor(group: UserGroup): string {
  const colors: any = {
    red: 'text-red-700 bg-red-100',
    pink: 'text-pink-700 bg-pink-100',
    grape: 'text-grape-700 bg-grape-100',
    violet: 'text-violet-700 bg-violet-100',
    indigo: 'text-indigo-700 bg-indigo-100',
    blue: 'text-blue-700 bg-blue-100',
    cyan: 'text-cyan-700 bg-cyan-100',
    teal: 'text-teal-700 bg-teal-100',
    green: 'text-green-700 bg-green-100',
    lime: 'text-lime-700 bg-lime-100',
    yellow: 'text-yellow-700 bg-yellow-100',
    orange: 'text-orange-700 bg-orange-100',
    gray: 'text-gray-700 bg-gray-100',
    lightgray: 'text-gray-500 bg-gray-100',
    slate: 'text-slate-700 bg-slate-100',
    stone: 'text-stone-700 bg-stone-100',
    rose: 'text-rose-700 bg-rose-100',
    emerald: 'text-emerald-700 bg-emerald-100',
    fuchsia: 'text-fuchsia-700 bg-fuchsia-100',
    amber: 'text-amber-700 bg-amber-100',
  };

  return colors[group.color] ? colors[group.color] : colors.gray;
}
