import React, { FC } from 'react';
import { Modal } from 'components/Modal';
import { translate } from 'utils/translations';
import DOMPurify from 'dompurify';
import { sanitizeOptions } from 'utils/sanitizeOptions';

export const RulesPopup: FC<{
  popupMessage: string;
  setPopupMessage: (text: string) => void;
}> = ({ popupMessage, setPopupMessage }) => {
  return (
    <Modal
      heading={''}
      onClose={() => {
        setPopupMessage('');
      }}
      isOpen={popupMessage !== ''}
      corner={
        <span
          className="text-blue-700 cursor-pointer"
          onClick={() => {
            setPopupMessage('');
          }}
        >
          {translate('Close')}
        </span>
      }
    >
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(popupMessage,sanitizeOptions()),
        }}
      ></div>
    </Modal>
  );
};
