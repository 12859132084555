export const sanitizeOptions = () => {
  return {
    ALLOWED_TAGS: [
      'strong',
      'b',
      'i',
      'input',
      'em',
      'u',
      'p',
      'br',
      'ul',
      'ol',
      'li',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'div',
      'span',
	  'table',
	  'thead',
	  'tbody',
	  'tr',
	  'th',
	  'td',
	  'tfoot',
	  'caption',
	  'blockquote',
    ],
    ALLOWED_ATTR: ['class', 'type'],
  };
};
