import * as React from 'react';
import {
  HashRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router-dom';

import { AppContext, AuthContext, GuardContext } from 'contexts';
import { Notifications } from 'components';
import Shell from 'components/layout/Shell';
import { BigLoader, SmallLoader } from 'components/Loader';
import { LoginPage } from 'views/auth/LoginPage';
import { ForgotPasswordPage } from 'views/auth/ForgotPasswordPage';
import { ResetPasswordPage } from 'views/auth/ResetPasswordPage';
import { fromQueryString } from 'utils/url';
import { InvitationPage } from 'views/auth/InvitePage';

const SamlRouter: React.FC = () => {
  const { config } = React.useContext(AppContext);

  return (
    <>
      <HashRouter>
        <Switch>
          <PublicRoute exact path="/" restricted>
            <Redirect to="/login" />
          </PublicRoute>
		  <PublicRoute exact path="/invitation/:campaign" restricted>
            <InvitationPage />
          </PublicRoute>
          <PublicRoute exact path="/login" restricted>
            <LoginPage />
          </PublicRoute>
          <PublicRoute exact path="/password/forgot" restricted>
            <ForgotPasswordPage />
          </PublicRoute>
          <PublicRoute exact path="/login/reset/:token/:email" restricted>
            <ResetPasswordPage invite={false} />
          </PublicRoute>
          <PublicRoute exact path="/login/create/:token/:email" restricted>
            <ResetPasswordPage invite={true} />
          </PublicRoute>
          <GuardContextProvider>{config && <Shell />}</GuardContextProvider>
        </Switch>
      </HashRouter>

      <Notifications />
      <SmallLoader />
      <BigLoader />
    </>
  );
};

const PublicRoute: React.FC<
  RouteProps & {
    restricted?: boolean;
  }
> = ({ restricted, ...props }) => {
  const { loggedIn } = React.useContext(AuthContext);
  const location = useLocation();
  const { redirectTo } = fromQueryString(location.search) as {
    redirectTo: string;
  };

  return loggedIn && restricted ? (
    <Redirect to={redirectTo || '/projects'} />
  ) : (
    <Route {...props} />
  );
};

const GuardContextProvider: React.FC = ({ children }) => {
  const { user } = React.useContext(AuthContext);
  const location = useLocation();

  if (!user) {
    // do not render when user is not logged in
    return <Redirect to={`/login?redirectTo=${location.pathname}`} />;
  }

  const guard = {
    user,
  };

  return (
    <GuardContext.Provider value={guard}>{children}</GuardContext.Provider>
  );
};

export default SamlRouter;
