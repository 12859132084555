import { MultiValueGenericProps } from 'react-select';

export const UserMultiValueContainer = (props: MultiValueGenericProps) => {
  return (
    <div className="bg-gray-100 px-2 py-2 rounded-lg flex items-center mr-2 mb-1">
      <div className="px-2">
        <p>{props.data?.name || props.data?.label}</p>
        {props.data?.email && (
          <p className="text-sm text-gray-600 whitespace-nowrap w-[120px] overflow-hidden truncate">
            {props.data?.email}
          </p>
        )}
      </div>
      <div className="border-l ml-2 ">{props?.children?.[1]}</div>
    </div>
  );
};

type TProps = {
  name?: string;
  email?: string;
  label: string;
  value: string;
};

export const UserOptionLabel = (props: TProps) => {
  return (
    <div style={{ flex: '10' }}>
      <p>{props?.name || props.label}</p>
      {props?.email && <p className="text-sm text-gray-600">{props?.email}</p>}
    </div>
  );
};

