import { FC, useRef } from 'react';
import { useParams, useHistory, Route, Switch } from 'react-router';
import { Supplier, SupplierContact } from '@contractool/schema';
import { http } from '../../utils/http';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { RouteTabs, Tab } from '../../components/Tabs';
import { pluralize as _ } from '../../utils/text';
import { Menu, MenuItem } from '../../components/Menu';
import { useRequest } from '../../utils/hooks/useRequest';
import { translate } from 'utils/translations';
import useToasts from 'hooks/useToasts';
import { Confirmation } from '../../components/Confirmation';
import SupplierForm from './SupplierForm';
import { SupplierContactModal } from '../../components/form/SupplierContactModal';
import { SupplierMenu } from './edit/SupplierMenu';
import { Formik } from 'formik';
import { useBigLoader } from 'components/Loader';
import { useQueryParams } from 'hooks/useParams';
import { SupplierParams } from './SuppliersPage';
import { toQueryString } from 'utils/url';
import { LinkWithQuery } from 'components/LinkWithQuery';
import { navigateTo } from 'utils/navigate';

const initialContact = {
  name: '',
  email: '',
  phone: '',
  id: 0,
  supplier_id: 0,
  url: '',
  is_default: false,
  fields: {},
};

export function SupplierEdit({ onUpdate }: { onUpdate: () => void }) {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const [supplier, { refresh }] = useRequest<Supplier | undefined>(
    `/api/suppliers/${id}`,
    undefined,
  );
  const [params] = useQueryParams<SupplierParams>({
    phrase: '',
    page: 1,
    per_page: 30,
  });
  const { success } = useToasts();

  if (supplier === undefined) {
    return null;
  }

  const close = () => {
    history.replace({
      pathname: '/suppliers',
      search: toQueryString(params),
    });
  };

  return (
    <>
      <Modal
        heading={translate('Supplier Detail')}
        onClose={close}
        contentClassName="h-124"
        corner={
          <SupplierMenu
            supplier={supplier}
            onRefresh={() => {
              onUpdate();
            }}
            onClose={() => close()}
          />
        }
      >
        <RouteTabs match={`/suppliers/${supplier.id}/edit/:tab`}>
          <Tab name="general" heading={translate('General')} className="pt-12">
            <GeneralTab
              supplier={supplier}
              onSuccess={(supplier: Supplier) => {
                close();
                onUpdate();
                success(
                  `${translate('Supplier :title was updated successfully', {
                    title: supplier.title,
                  })}`,
                );
              }}
              onCancel={() => close()}
            />
          </Tab>

          <Tab
            name="contacts"
            heading={_({
              _: `:count ${translate('Contacts')}`,
              1: `:count ${translate('Contact')}`,
            })(supplier.contacts_count || 0)}
          >
            <ContactsTab
              supplier={supplier}
              onRefresh={() => refresh()}
              onCancel={() => close()}
              onSuccess={() => onUpdate()}
            />
          </Tab>
        </RouteTabs>
      </Modal>
    </>
  );
}

const GeneralTab: FC<{
  supplier: Supplier;
  onSuccess: (supplier: Supplier) => void;
  onCancel: () => void;
}> = ({ supplier, onSuccess, onCancel }) => {
  const { start, stop } = useBigLoader();
  const formRef = useRef<HTMLFormElement>();

  const handleSubmit = (values: Supplier) => {
    start(translate('Saving...'));
    http
      .put(supplier.url, values)
      .then(() => onSuccess(values))
      .finally(() => stop());
  };

  return (
    <>
      <SupplierForm
        supplier={supplier}
        onSubmit={(formSubmission) => handleSubmit(formSubmission)}
        ref={formRef}
      />
      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onCancel}>
          {translate('Cancel')}
        </Button>

        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            formRef?.current?.submit();
          }}
        >
          {translate('Save Changes')}
        </Button>
      </Modal.Footer>
    </>
  );
};

const ContactsTab: FC<{
  supplier: Supplier;
  onRefresh: () => void;
  onCancel: () => void;
  onSuccess: () => void;
}> = ({ supplier, onRefresh, onCancel, onSuccess }) => {
  const history = useHistory();

  const { success } = useToasts();

  return (
    <Formik
      initialValues={initialContact}
      onSubmit={(values) =>
        http.put(supplier.url, values).then(() => {
          success(translate('Contact was updated successfully'));
        })
      }
    >
      <div className="h-110 pt-12 overflow-auto">
        <table className="w-full table-fixed">
          <tbody>
            {supplier.contacts.map((contact) => (
              <tr key={contact.id}>
                <td className="pb-8 w-14">
                  <ContactAvatar contact={contact} />
                </td>
                <td className="pb-8 pr-4 truncate">{contact.name}</td>
                <td className="pb-8 truncate text-gray-600" title="hello">
                  {contact.email}
                </td>
                <td className="pb-8 pl-4 text-gray-600">{contact.phone}</td>
                {contact.is_default ? (
                  <td className={'pb-8 pl-4 text-gray-600'}>
                    {translate('Default contact')}
                  </td>
                ) : (
                  <td className={'pb-8 pl-4 text-gray-600'} />
                )}
                <td className="pb-8 pr-4 w-10 text-right">
                  <div className="flex justify-end">
                    <Menu autoClose={false} handle="more_vert">
                      <LinkWithQuery
                        to={`/suppliers/${supplier.id}/edit/contacts/${contact.id}/edit`}
                        className="items-center"
                      >
                        <MenuItem icon="edit">{translate('Edit')}</MenuItem>
                      </LinkWithQuery>
                      <Confirmation
                        onConfirm={() => {
                          http.delete(contact.url).then(() => {
                            onRefresh();
                            onSuccess();
                          });
                        }}
                        trigger={({ onClick }) => (
                          <MenuItem
                            icon="remove_circle"
                            onClick={() => {
                              onClick();
                            }}
                          >
                            {translate('Remove')}
                          </MenuItem>
                        )}
                        heading={translate('Remove contact')}
                        buttonText={translate('Yes, remove')}
                        color="red"
                      >
                        {translate(
                          "Are you sure you want to remove contact :name ? You can't undo this action.",
                          { name: contact.name },
                        )}
                      </Confirmation>
                    </Menu>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pb-8">
          <LinkWithQuery
            to={`/suppliers/${supplier.id}/edit/contacts/new`}
            className="inline-flex items-center"
          >
            <Button
              color="white"
              size="small"
              radius="full"
              icon="add"
              tabIndex={-1}
              className={'cypress_add_contact_button'}
            ></Button>

            <span className="text-gray-600 ml-4">
              {translate('Add contact')}
            </span>
          </LinkWithQuery>
        </div>

        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={onCancel}>
            {translate('Cancel')}
          </Button>
        </Modal.Footer>

        <Switch>
          <Route path={`/suppliers/${supplier.id}/edit/contacts/new`}>
            <NewContactModal
              supplier={supplier}
              onCancel={() => {
                navigateTo({
                  history,
                  path: `/suppliers/${supplier.id}/edit/contacts`,
                  preserveQuery: true,
                });
              }}
              onSuccess={(values) => {
                success(
                  translate('Contact :title was created successfully', {
                    title: values.name,
                  }),
                );
                navigateTo({
                  history,
                  path: `/suppliers/${supplier.id}/edit/contacts`,
                  preserveQuery: true,
                });
                onRefresh();
                onSuccess();
              }}
            />
          </Route>
          <Route
            path={`/suppliers/${supplier.id}/edit/contacts/:contactId/edit`}
          >
            <EditContactModal
              supplier={supplier}
              onCancel={() => {
                navigateTo({
                  history,
                  path: `/suppliers/${supplier.id}/edit/contacts`,
                  preserveQuery: true,
                });
              }}
              onSuccess={(values) => {
                success(
                  translate('Contact :title was updated successfully', {
                    title: values.name,
                  }),
                );
                navigateTo({
                  history,
                  path: `/suppliers/${supplier.id}/edit/contacts`,
                  preserveQuery: true,
                });
                onRefresh();
                onSuccess();
              }}
            />
          </Route>
        </Switch>
      </div>
    </Formik>
  );
};

// TODO: generate background/foreground color
export const ContactAvatar: FC<{ contact: SupplierContact }> = ({
  contact,
}) => {
  const initials = contact.name
    .split(' ')
    .map((word) => word.charAt(0))
    .map((char) => char.toUpperCase())
    .join('');

  const colors = [
    'bg-lime-700',
    'bg-red-700',
    'bg-blue-700',
    'bg-orange-700',
    'bg-pink-700',
    'bg-yellow-700',
    'bg-green-700',
    'bg-gray-700',
    'bg-teal-700',
    'bg-indigo-700',
  ];

  return (
    <div
      className={`w-10 h-10 flex items-center justify-center text-white ${
        colors[contact.id % 10]
      } rounded-full`}
    >
      {initials[0] + initials[initials.length - 1]}
    </div>
  );
};

const NewContactModal: FC<{
  supplier: Supplier;
  onCancel: () => void;
  onSuccess: (contact: SupplierContact) => void;
}> = ({ supplier, onSuccess, onCancel }) => {
  return (
    <SupplierContactModal
      label={translate('Add contact')}
      initialValues={initialContact}
      onCancel={onCancel}
      onSuccess={(values) => {
        http.post(supplier.contacts_url, values).then(() => onSuccess(values));
      }}
    />
  );
};

const EditContactModal: FC<{
  supplier: Supplier;
  onCancel: () => void;
  onSuccess: (contact: SupplierContact) => void;
}> = ({ supplier, onSuccess, onCancel }) => {
  const { contactId } = useParams<{ contactId?: string }>();
  const contact = supplier.contacts.find(
    (c) => contactId && c.id === parseInt(contactId),
  );
  if (!contact) return null;

  return (
    <SupplierContactModal
      label="Edit contact"
      initialValues={contact}
      onCancel={onCancel}
      onSuccess={(values) => {
        http.put(contact.url, values).then(() => onSuccess(values));
      }}
    />
  );
};
