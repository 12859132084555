import { User } from '@contractool/schema';
import { getActiveBackup, getActiveDelegator } from 'utils/delegation';
import { translate } from 'utils/translations';
import { Icon } from './Icon';
import { Link, useHistory } from 'react-router-dom';

const DelegationBadge: React.FC<{ user: User }> = ({ user }) => {
  const history = useHistory();

  const delegations = user?.delegations.filter(
    (item) => item != null && item?.is_active,
  );

  if (delegations?.length > 0) {
    if (getActiveBackup(delegations, user.id)) {
      return (
        <Link
          to={{
            pathname: `${history.location.pathname}/profile/delegation`,
            state: { prevLocation: history.location.pathname },
			search: history.location.search
          }}
        >
          <span
            data-tip={
              translate('You work on behalf of ') +
              delegations?.[0]?.delegator.name
            }
          >
            <Icon
              className="text-gray-500 fill-current mr-4"
              name="delegatee"
              size={14}
            />
          </span>
        </Link>
      );
    }
    if (getActiveDelegator(delegations, user.id)) {
      return (
        <Link
          to={{
            pathname: `${history.location.pathname}/profile/away_status`,
            state: { prevLocation: history.location.pathname },
			search: history.location.search
          }}
        >
          <span
            data-tip={
              translate('You are substituted by ') +
              delegations?.[0]?.backup.name_email
            }
          >
            <Icon
              className="text-gray-500 fill-current mr-6"
              name="delegated"
              size={6}
            />
          </span>
        </Link>
      );
    }
  }
  return <></>;
};

export default DelegationBadge;
